.transparent-input,
.transparent-input:focus,
.transparent-input:focus-within {
  font-size: 30px;
  background: inherit;
  border: none;
  color: white;
  width: 100%;

  &.socialLinks {
    font-size: 23px;
    background: none;
    border: inherit;
  }
}

.transparent-description,
.transparent-description:focus,
.transparent-description:focus-within {
  color: #68717e;
  font-weight: 400;
  background: inherit;
  font-size: 12px;
  border: none;
  width: 100%;
  line-height: 20px;
}

.actions {
  float: right;
  display: none;
  .action_type {
    cursor: pointer;
    // display: none;
    // background-color: white;
    width: 20px;
    height: 20px;
  }
}

.socialIconBox {
  top: 992px;
  border-radius: 50%;
  float: left;
  margin-left: 8px;
  width: 46px;
  height: 46px;
  opacity: 1;

  &.preview {
    float: none;
    margin-top: 8px;
  }

  &.twitch {
    background: #673ab7 0% 0% no-repeat padding-box;
  }

  &.facebook {
    background: #4c70aa 0% 0% no-repeat padding-box;
  }

  &.twitter {
    background: #2aaae0 0% 0% no-repeat padding-box;
  }

  &.instagram {
    background: transparent linear-gradient(165deg, #6f4cc6 0%, #d02a89 42%, #fcbc5c 100%) 0% 0% no-repeat padding-box;
  }

  &.youtube {
    background: #ca3738 0% 0% no-repeat padding-box;
  }
}

.socialIcon {
  margin: 33%;
}

.button .socialIconSave {
  margin: 0;
}

.editContainer {
  padding-top: 40px;
}

// You can't add a global styling like this in a child scss file - it is screwing other styling on other parts of the site
// you will need to target header within a specific div or class
// header {
//     margin-top:35px;
// }

.partnerLogo {
  float: left;
  margin: 1.5%;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
