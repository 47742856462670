.your-matches-top-right-box {
  border: 1px solid $danger;
  border-radius: 2px;
  padding: 14px 22px;
}

.teamDetailsSpace {
  left: 7px;
}

.vsSpace {
  padding: "8px 12px";
}

.leagueDetailSpace {
  max-width: 305px;
}

.transparentButton {
  position: absolute;
  color: white;
  background: none;
  border-color: $danger;
  height: 50px;
  cursor: pointer;
  width: 300px;
  top: 0;
  right: 0;
  margin-top: 28px;
  margin-right: 32px;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  &.your-matches{
    width:325px;
  }

  &:hover {
    // background-color: $danger;
    // border: 2px solid $danger;
    text-shadow:
      -2px -2px 10px rgb(168, 161, 161),
      2px -2px 10px rgb(168, 161, 161),
      -2px 2px 10px rgb(168, 161, 161),
      2px 2px 10px rgb(168, 161, 161);
    box-shadow: none;
  }
}

.sortable-list.your-matches-sortable-list {
  .sortable-expandable-content {
    .player-position-form {
      display: flex;

      .position-info {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      .player-position-option-col {
        flex: 1;

        padding-right: 30px;

        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }

  ul.list li {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6 {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .faded {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .image-40 {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  .game-logo {
    width: 22px;
    height: 22px;
    opacity: 0.5;
  }

  .your-matches-sortable-list-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
