.notification-single {
  font-size: 0.85rem;
  padding: 0 0 26px 25px;

  p {
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .notif-single-title {
    font-weight: bold;
  }

  .notif-single-body {
    color: #bbb;
  }

  .notif-single-date {
    color: #999;
  }

  button {
    margin-right: 15px;

    &:last-child {
      margin: 0;
    }
  }
}

.notification-icon-span {
  display: flex;
  flex-direction: row;
.fas.fa-bell{
  font-size: 25px;
    padding-left: 5px;
}
}

.fa-ul {
  margin-left: 1.1em !important;
}

.notification-image {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0px;
  background-size: cover;
  background-position: 50% 50%;
  line-height: 45px;
  position: relative;
}