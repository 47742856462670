.arrow-bg {
  .slick-arrow {
    width: 39px;
    height: 39px;

    &.slick-next {
      margin-right: 30px;
    }
    &.slick-prev {
      margin-left: 10px;
    }
    &.slick-prev::before {
      
        font-size: 25px !important;
      
    }
    &.slick-next,
    &.slick-prev {
      background-color: rgba(18, 20, 26, 0.8);
      top: 29.5%;
    }
    &.slick-next:hover,
    &.slick-prev:hover {
      background-color: rgba(18, 20, 26, 1);
    }
  }
}


// .slider-container .slick-arrow.slick-prev::before {
//     font-size: 23px !important;
// }
