
.progress {
    border-radius: 100px;
    height: 40px;
    font: normal normal normal 14px Montserrat;
    overflow: hidden;

}
.turn {
    background:  #30180E;
    .progress-bar {
        background-color: var(--primary);
    }
}
.waiting {
    background:  #12161D;
    .progress-bar {
        background-color: #5B6572;
    }
}
.ended {
    background: #06211A;
    .progress-bar {
        background-color: #19DB60;
    }
}

.progress-bar {
    justify-content: center;
    text-align: center;
    height: 40px;
    transition: width 2s ease;
}
.progress-bar-title {
    position: absolute;
    text-align: center;
    line-height: 40px; /* line-height should be equal to bar height */
    overflow: hidden;
    color: #fff;
    right: 0;
    left: 0;
    font-weight: bold;
}
.icon {
    margin-right: 12px;
    font-weight: bold;
}
.maps-list {
    text-align: center;
    margin: auto;
    padding: 10px;
}
.map {
    height: 50px;
    margin: auto;
    line-height: 50px;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-size: 12px;
    max-height: 50px;
    cursor: pointer;
    border: 2px solid transparent;

    .map-action {
        display: none;
    }
    .map-title {
        text-transform: uppercase;
    }
}
.map-bg {
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    width: 100%;
    height: 100%;
}
.ban:hover, .pick:hover {
    .map-title {
        display: none;
    }
    .map-action {
        display: contents;
    }
}
.ban:hover, .map-banned {
    border: 2px solid #F11857;
}
.pick:hover, .map-picked {
    border: 2px solid #19DB60;
}
.map-title-ban, .banned {
    color: #F11857;
    text-transform: uppercase;
}
.map-title-pick, .picked {
    color:#19DB60;
    text-transform: uppercase;
}
.map-action {
    text-transform: uppercase;
}
.step-heading {
    text-transform: uppercase;
    text-align: center;
    
}
.turn-heading {
    color: var(--primary);
}
.wait-heading {
    color: #556071;
}
.past {
    color: #68717e !important;
    border-bottom: 2px solid #68717e !important;
}
.current {
    color: #fff !important;
    border-bottom: 2px solid #fff !important;
}
.next {
    color: #68717e !important;
    border-bottom: 2px solid #171921 !important;
}
.veto-step-tab {
    margin-right: 3px !important;
    width: 100%;
}
._nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0 !important;
}
.stat {
    background: #0F1116 0% 0% no-repeat padding-box;
    border: 1px solid #22262E;
    text-align: left;
    white-space: nowrap;
}
.player-stats {
    font-size: 10px;
    text-transform: uppercase;
      .mini-pie-chart {
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        // background: conic-gradient(var(--primary) 0, var(--primary) 0%, darken(#E55C13, 30%) 0, #E55C13 100%);

    &::after {
      position: absolute;
      content: "";
      top: 8px;
      left: 8px;
      height: 24px;
      width: 24px;
      background-color: #0F1116;
      border-radius: 25px;
    }
  }

}
.player-img {
    border-radius: 100%;
  }
.pos-icon {
    filter: invert(45%) sepia(13%) saturate(402%) hue-rotate(176deg) brightness(94%) contrast(91%);
}
.veto-steps {
    border-bottom: none !important;
    display: block !important;
}
.default-text {
    color: var(--primary);
}
.veto-step-tabs {
    .tabs-nav {
        border-bottom: none !important;

    }

}

.side-pick-btn {
    cursor: pointer;
}
.pick-side {
    // cursor: pointer;
    pointer-events: all !important;
}

.map-img {
//    background-position: center;
//    background-size: cover;
   width: 100%;
   height: 30px;
   border-top-right-radius: 10px;
   
}
.veto-icon {
    color: var(--primary);
}
.button.join-button {
    background: #15BD53 0% 0% no-repeat padding-box;
    color: black;
    text-transform: uppercase;
}
.game-link {
    border: 1px solid #22262E;
    color: #68717E;
    background-color: #03060D;
    border-radius: 2px;
    outline: 0;
    font-size: 12px;
    font: normal normal normal 12px Montserrat;
    align-items: center;

}
.link-icon {
    margin-right: 5px;
    cursor: pointer;
}
.completed-map {
    color: white;
    font-size: 14px;
    font-weight: bold;
    // height: 50px;
    margin: auto;
    // line-height: 50px;
    // margin-top: 10px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    // font-size: 12px;
    // max-height: 50px;
    cursor: pointer;
    
}

.button.waiting-server-button {
    background: gray 0% 0% no-repeat padding-box;
    color: white;
    text-transform: uppercase;
    pointer-events: none;
    cursor: none;
}

.color-warning {
    color: #D5901F;
}





