.game-profile-list {
  margin-bottom: 30px;
.position-icon {width: 24px;margin-right:12px}
  .title {
    display: flex;
    margin-bottom: 20px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }

  .game-profile-list-item {
    margin-top: 10px;
    padding: 10px 22px;
    background-color: $bg-faded;
    border: 1px solid $hr-color;

    [class^="col"] {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:last-child {
        justify-content: space-between;
        display: inline-grid;
      }
    }

    .game-profile-lg-image {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 12px;
      background-size: cover;

      &.profile-image-with-initials {
        background: #666;
        line-height: 40px;
        text-align: center;
      }
    }

    .game-profile-lg-image-team {
      width: 40px;
      height: 40px;
      border-radius: 0% !important;
      margin-right: 12px;
      background-size: cover;

      &.profile-image-with-initials {
        background: #666;
        line-height: 40px;
        text-align: center;
      }
    }

    .game-profile-sm-image {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      background-size: cover;
    }
  }

  .game-profile-list-th {
    padding-left: 22px;
    padding-right: 22px;
  }
}


.results-detail-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-decoration: none;

  .text-14 {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.coach-view-profile{
  position: absolute;
  right: 2%;
}