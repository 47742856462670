.filter-text{
  margin-top: 20px;
  margin-left: 30px;
  font-weight: bold;
}
.filter-checkbox{
  // margin-right: 30px;
  position: absolute;
  right: 5%;
  bottom: 0%;
  .checkbox-container {
    margin-top: 5%;
  }
  .check-label {
    margin-bottom: 0px;
  }
}
.filter-tabs{
  border-bottom: 0px;
  overflow: hidden;
}
#scrolling_div::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
}
#scrolling_div::-webkit-scrollbar {
    width: 15px !important;
}
#scrolling_div::-webkit-scrollbar-track {
    border-left: 1px #22262e solid;
    background-color: #0F1519 !important;
}
.filter-tab-content::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 9999px;
}
.filter-tab-content::-webkit-scrollbar {
  width: 15px !important;
}
.filter-tab-content::-webkit-scrollbar-track {
  border-left: 1px transparent solid;
  border-left: 1px solid transparent;
  background-color: #0F1519 !important;
}
