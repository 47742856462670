.tournament-join-modal {
    .team-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &.modal-body-contents {
        max-height: 450px;
        overflow: auto;
    }
}

.loaderOnSameBG {

}

button:disabled {
   
    :not(.disabled-opacity) {
      opacity: 1 !important;
      height: auto;
    }
}