.sortable-list.champions-stats-sortable-list {
	.sortable-list-header button {
		white-space: normal;
		display: flex;
		align-items: center;

		.icon img {
			margin-left: 3px;
			margin-bottom: -1px;
		}
	}
	.sortable-list-header {
		.col-1,
		.col-2 {
			display: flex;
			align-items: flex-end;
		}
	}
}
.accept-request{
	display:flex;
	justify-content:space-between;
}
