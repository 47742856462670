
.veto-wrapper {
    text-align: center;
    border: 3px solid;
    opacity: 1;
    margin: auto;
    padding: 50px;

    .header {
        font: normal normal bold 22px Montserrat;
        letter-spacing: 0.66px;
        text-transform: uppercase;
        opacity: 1;
    }

    .text {
        font: normal normal normal 14px Montserrat;
        letter-spacing: 0px;
        opacity: 1;

    }

    .icon {
        margin-right: 5px;
    }
}

.veto-wrapper.waiting, .veto-wrapper.opponent, .veto-wrapper.picking, .veto-wrapper.waitforopponent, .veto-wrapper.admin {
    border-color: #6C2E0C;
    color: var(--primary);
    background-image: linear-gradient(271deg, #D5901F00 0%, #D5901F45 100%), url('../../../../img/veto-background.png');
    background-position: center;
    background-size: cover;

    .icon {
        img {
            filter: invert(48%) sepia(51%) saturate(5065%) hue-rotate(357deg) brightness(94%) contrast(91%)
        }
    }

}

.veto-wrapper.open {
    border-color: #0F6C31;
    color: #19DB60;
    background-image:
        linear-gradient(271deg, #19DB6007 0%, #19DB601F 100%), url('../../../../img/veto-background.png');
    background-position: center;
    background-size: cover;

    .icon {
        img {
            filter: invert(75%) sepia(71%) saturate(3175%) hue-rotate(88deg) brightness(102%) contrast(84%);
        }
    }
}

.veto-wrapper.loading {
    border-color: #0F6C31;
    color: #19DB60;
    background-image:
        linear-gradient(271deg, #19DB6007 0%, #19DB601F 100%), url('../../../../img/veto-background.png');
    background-position: center;
    background-size: cover;

    .icon {
        img {
            filter: invert(75%) sepia(71%) saturate(3175%) hue-rotate(88deg) brightness(102%) contrast(84%);
        }
    }
}

.veto-countdown {
    font: bold 16px Montserrat;
    position: relative;
}

.button.open-button {
    background: #56B85B 0% 0% no-repeat padding-box;
    color: black;
    text-transform: uppercase;
}
.button.picking-button {
    background: var(--primary) 0% 0% no-repeat padding-box;
    color: black;
    text-transform: uppercase;
}
.button.faded-btn {
    background: #0F1116;
    color: white;
    text-transform: uppercase;
    pointer-events: none;
}
.overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.veto-loader {
  text-align: center;
  
}



