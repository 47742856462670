
.sponsorbanner{
  background-color: #12141a;
  padding: 4rem;
  margin-block: 4rem;
  &_image{
    object-fit: contain;

    &_container{
      width: 33%;
      display:flex;
      justify-content: space-around;
    }
  }
  &_inner{
    display: flex;
    gap: 2rem;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    max-width: 1280px;
    padding-inline: 30px;
    margin-inline: auto;
  }
  &_heading{
    font-size: 3rem;
    margin: 0px;
    line-height: 1.2;
    font-weight: 700;
    text-align: end;
    border-right: 2px solid #fff;
    padding-right: 12px;
  }
}