.position-display-list {
	list-style: none;
	overflow: hidden;
	border: 1px solid $tab-border-color;
	border-radius: 2px;
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	padding: 0px;
	margin: 0px;

	li {
		padding: 12px 0px;
		flex: 1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		&.active {
			background-color: $tab-border-color;
		}
	}
}
