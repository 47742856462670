.mirror-text {
	position: absolute;
	top: 35px;
	right: 35px;
	color: var(--primary);
	border-top: 2px solid var(--primary);
	padding: 3px 0px 0px 5px;
	min-width: 140px;

	&.static {
		position: static;
		margin-top: 2px;
	}

	span {
		font-weight: 500;
		line-height: 15px;
		font-size: 12px;
		letter-spacing: 1px;
		padding-top: 3px;
		text-transform: uppercase;
		display: block;
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);

		filter: FlipH;
	}
}
