.button-link {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

.round-toggle-button {
  width: 26px;
  height: 26px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  flex-shrink: 0;
}

.button {
  display: inline-block;
  height: 43px;
  padding: 9px 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  background-color: #666;
  color: white;
  border: 0;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &.default {
    background-color: $border-color;
  }

  &.bordered {
    background-color: transparent;
    border: 2px solid $border-color;
    &:hover {
      background-color: $border-color;
    }
  }

  &.medium {
    height: 36px;
    padding: 0px 13px;
    font-size: 12px;
  }

  &:disabled {
   
    :not(.disabled-opo) {
      opacity: 0.3;
    }
    
    
    cursor: not-allowed;
    &.high-visible {
      opacity: 0.7;
    }
  }

  &.thicc {
    display: block;
    width: 100%;
    background-color: transparentize($primary, 0.5);
  }

  &.loading {
    padding: 7px;
  }

  /* SIZING */
  &.small {
    height: 28px;
    padding: 8px 12px;
    font-size: 10px;
    line-height: 13px;

    &.bordered {
      padding: 5px 12px;
    }
  }

  /* COLOURS */
  &.transparent {
    background: none;
    padding: 14px 0;
  }

  &.primary {
    background-color: var(--primary);
    &:hover {
      box-shadow: -1px -1px 5px var(--primary),
                   1px -1px 5px var(--primary), 
                  -1px 1px 5px var(--primary),
                   1px 1px 5px var(--primary);
    }
    &:disabled{
      // opacity: 0.5;
    }
  }

  &.danger {
    background-color: $danger;
    &:hover {
      box-shadow: -1px -1px 5px rgba(241, 24, 87, 0.7), 1px -1px 5px rgba(241, 24, 87, 0.7), -1px 1px 5px rgba(241, 24, 87, 0.7),
        1px 1px 5px rgba(241, 24, 87, 0.7);
    }
  }

  &.cta-button,
  &.cta-button-transparent {
    height: auto;

    position: relative;
    background-color: var(--primary);

    &.disabled-opo {
      background-color: $text-faded-color;
      opacity: none;
    }
    border-radius: 0px;

    .top,
    .bottom,
    .left,
    .right,
    .left-corner,
    .right-corner {
      position: absolute;
      background-color: $white;
    }

    .top {
      width: calc(100% - 10px);
      height: 1px;
      top: -5px;
      left: 15px;
    }

    .bottom {
      width: calc(100% - 10px);
      height: 1px;
      bottom: -5px;
      right: 15px;
    }

    .left {
      height: calc(100% - 10px);
      width: 1px;
      bottom: -5px;
      left: -5px;
    }

    .right {
      height: calc(100% - 10px);
      width: 1px;
      top: -5px;
      right: -5px;
    }

    .left-corner {
      height: 28px;
      width: 1px;
      bottom: -9px;
      right: 5px;
      transform: rotate(45deg);
    }

    .right-corner {
      height: 28px;
      width: 1px;
      top: -9px;
      left: 5px;
      transform: rotate(45deg);
    }
  }

  &.cta-button,
  &.cta-button-transparent1 {
    height: auto;

    position: relative;
    background-color: var(--primary);

    &.disabled-opo {
      background-color: $text-faded-color;
      opacity: none;
    }
    border-radius: 0px;

    .top,
    .bottom,
    .left,
    .right,
    .left-corner,
    .right-corner {
      position: absolute;
      background-color: $white;
    }

    .top {
      width: calc(100% - 10px);
      height: 1px;
      top: -5px;
      left: 15px;
    }

    .bottom {
      width: calc(100% - 10px);
      height: 1px;
      bottom: -5px;
      right: 15px;
    }

    .left {
      height: calc(100% - 10px);
      width: 1px;
      bottom: -5px;
      left: -5px;
    }

    .right {
      height: calc(100% - 10px);
      width: 1px;
      top: -5px;
      right: -5px;
    }

    .left-corner {
      height: 28px;
      width: 1px;
      bottom: -9px;
      right: 5px;
      transform: rotate(45deg);
    }

    .right-corner {
      height: 28px;
      width: 1px;
      top: -9px;
      left: 5px;
      transform: rotate(45deg);
    }
  }
  

  &.cta-button {
    padding: 16px 38px;
    transition: margin 0.3s, padding 0.3s;
    // , &:not(.lockAnimation) 
    &:hover:not(.lockAnimation)  {
      margin: -5px;
      padding: 21px 43px;

      .top,
      .bottom,
      .left,
      .right,
      .left-corner,
      .right-corner {
        opacity: 0;
      }
    }

    .top,
    .bottom,
    .left,
    .right,
    .left-corner,
    .right-corner {
      transition: opacity 0.3s;
    }
  }

  &.cta-button-transparent {
    padding: 14px 30px;
    background-color: transparent;
    color: var(--primary);
    margin-left: 5px;
    transition: text-shadow 0.3s;

    &:hover {
      // background-color: transparentize($primary, 0.75);
      text-shadow: 
        -2px -2px 10px var(--primary), 
        2px -2px 10px var(--primary),
        -2px 2px 10px var(--primary), 
        2px 2px 10px var(--primary);
      box-shadow: none;
    }

    .top,
    .bottom,
    .left,
    .right,
    .left-corner,
    .right-corner {
      transition: opacity 0.3s;

      background-color: var(--primary);
    }

    .right,
    .left {
      height: calc(100% - 10px);
    }

    .left-corner {
      height: 28px;
      width: 1px;
      bottom: -9px;
      right: 5px;
    }

    .right-corner {
      height: 28px;
      width: 1px;
      top: -9px;
      left: 5px;
    }

    .bottom {
      width: calc(100% - 10px);
      height: 1px;
      bottom: -5px;
      right: 15px;
    }

    .top {
      width: calc(100% - 10px);
      height: 1px;
      top: -5px;
      left: 15px;
    }
  }

  &.cta-button-transparent1 {
    padding: 14px 30px;
    background-color: transparent;
    color: var(--primary);
    margin-left: 5px;
    width: 99% !important;
    transition: text-shadow 0.3s;

    &:hover {
      // background-color: transparentize($primary, 0.75);
      text-shadow: 
        -2px -2px 10px var(--primary), 
        2px -2px 10px var(--primary),
        -2px 2px 10px var(--primary), 
        2px 2px 10px var(--primary);
      box-shadow: none;
    }

    .top,
    .bottom,
    .left,
    .right,
    .left-corner,
    .right-corner {
      transition: opacity 0.3s;

      background-color: var(--primary);
    }

    .right,
    .left {
      height: calc(100% - 10px);
    }

    .left-corner {
      height: 28px;
      width: 1px;
      bottom: -9px;
      right: 5px;
    }

    .right-corner {
      height: 28px;
      width: 1px;
      top: -9px;
      left: 5px;
    }

    .bottom {
      width: calc(100% - 10px);
      height: 1px;
      bottom: -5px;
      right: 15px;
    }

    .top {
      width: calc(100% - 10px);
      height: 1px;
      top: -5px;
      left: 15px;
    }
  }

  &.wide-primary {
    background-color: transparentize($primary, 0.9);
    color: var(--primary);
    display: block;
    width: 100%;
    padding: 18px;
    text-transform: uppercase;
    border: solid 1px transparentize(#707070, 0.9);
    font-weight: bold;
    height: auto;
    margin: 12px 0px;
  }
}
