.reorder-list {
}

.reorder-item {
  color: white;
  padding: 15px;
  margin-bottom: 10px;
  background: #222;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  pointer-events: visible !important;

  &.hidden {
    opacity: 0.5;
  }

  span {
    color: #999;
    display: flex;
    align-items: center;

    label {
      margin: 0 15px 0 5px;
      font-size: 0.7rem;
    }

    svg {
      font-size: 1.3rem;
      color: #444;
    }
  }
}
