@import "./OrganisationsManage/ProfileSettings/profile-settings";


.dropzone {
    background: none;
    border-top: none;
    border-bottom: none;
    padding: 0 25%;
    border-color: $text-faded-color;
  }
  