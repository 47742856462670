.home-hero {
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 86px 0px;
  position: relative;

  .home-hero-background,
  .home-hero-video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -2;
  }

  .home-hero-background {
    background: linear-gradient(to top, #03060d 10%, rgba(3, 6, 13, 0) 300%);
  }

  .home-hero-video {
    z-index: -3;
    object-fit: cover;
  }

  .home-hero__heading {
    .glitch {
      width: max-content;
    }
  }
  h1 {
    line-height: 52px;
    font-size: 42px;
    margin-top: 19px;
    padding-bottom: 64px;
    margin-bottom: 0px;
  }

  h2 {
    font-size: 18px;
  }

  span.faded {
    font-size: 14px;
    font-weight: 500;
  }

  .row {
    max-width: 950px;
    //margin: 60px;
    margin-bottom: 58px;
  }

  .col-4 {
    text-align: center;

    p {
      color: $text-faded-color;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .icon img {
    height: 40px;
  }
}
