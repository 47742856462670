@import "./Banner/banner";
@import "./SponsorBanner/sponsorbanner";
p {
  text-align: justify;
  line-height:1.75;
} 
  .icon-cross {
    left: 50px;
    top: 50px;
    position: absolute;
    transform: rotate(20deg);
  }
  .icon-cross-2 {
    right: 50px;
    top: 50px;
    position: absolute;
    transform: rotate(20deg);
  }
  .icon-play {
    position: absolute;
    left: 50px;
    bottom: 50px;
  }
  .icon-play-2 {
    position: absolute;
    right: 90px;
    bottom: 50%;
    transform: translateY(50%);
    transform: rotate(180deg);
  }
  .icon-play-3 {
    position: absolute;
    right: 50px;
    bottom: 50px;
  }
  .icon-play-4 {
    position: absolute;
    left: 90px;
    bottom: 50%;
    transform: translateY(50%);
    transform: rotate(180deg);
  }
.video_container {
  overflow: hidden;
  width: 100%;
  & video {
    aspect-ratio: 16/9;
    width: 100%;
    max-height: 45vh;
    object-fit: cover;
    object-position: center center;
  }
}
.about {
  position: relative;
  max-width: 1280px;
  margin-inline: auto;
  padding-inline: 30px;
  &_container{
    position: relative;
  }
  &_heading{
    font-size: 3rem;
    line-height: 1.2;
    margin-block: 5rem;
    &_highlighted{
      color: $primary;
    }
  }
  &_article {
    display: flex;
    justify-content: space-between;
    &_heading{
      font-size: 2rem;
      text-transform: uppercase;
      &_highlighted{
        color: $primary;
      }
    }
    &_texts{
      max-width: 45%;
    }
    &_images {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    &_image {
      border-radius: 10px;
      object-fit: cover;
      width: 400px;
      aspect-ratio: 1/1;
      &_laptop{
        width: 535px;
        height: 429px;
        z-index: 2;

      }
      &_screen{ 
        width: 476px;
        height: 621px;
        margin-left: -15rem;
        margin-top: -12rem;
        object-fit: contain;
      }
    }
  }
  &_second_article{
    margin-top: -10rem;
  }
  .mirror-text {
    top: 0px;
    right: 30px;
  }
}
.spacing{
  margin-block: 4rem;
}

.align-center{
  align-items: center;
}

.gap {
  gap: 8rem;
}

.seen_on_list{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  padding-block: 2rem;
  &_heading{
    margin-bottom: 2rem;
    margin-top: 0px;
    font-size: 3rem;
    text-transform: uppercase;
    color: #12141a;
  }
  &_grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 3rem;
    align-items: center;
    &_image{
      object-fit: cover;
      max-width: 300px;
    }
  }
}
.larger-width {
  max-width: 50%;
}

.no-aspect-ratio {
  aspect-ratio: auto;
}

.socials{
  max-width: 1280px;
  padding-inline: 30px;
  margin-inline: auto;
  letter-spacing: 1.5px;
  display:flex;
  width:100%;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  & a {
    text-decoration: none;
  }
  &_container{
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}