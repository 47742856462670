@import "./../News/NewsItem/news-item";
@import "./LeaguesSingle/LeaguesSingleTabs/Announcements/announcements";
@import "./LeaguesSingle/LeaguesSingleTabs/Overview/overview";
@import "./LeaguesSingle/LeaguesSingleTabs/Prizes/prizes";
@import "./LeaguesSingle/LeaguesSingleTabs/Rules/rules";

.league-header {
  height: 116px;
}

.leagues-cover-guide {
  max-width: 1200px;
  margin: 20px auto 0 auto;
}

.game-profile-lg-image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
  background-size: cover;

  &.profile-image-with-initials {
    background: #666;
    line-height: 40px;
    text-align: center;
  }
}
