.graybox{
  background-color: #12141a;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  // height: 60vh;
  // max-height: 60vh;
  border: 1px solid #22262e;
  // scroll-behavior: smooth;
}
.smoothScroll{
  scroll-behavior: smooth;
}
.graybox.filter-tabs {
  border-bottom: 1px solid #22262e;
  max-height: none;
  margin-top: 21px !important;
}
.filter-tab-content {
  height: fit-content;
  overflow-y: auto;
  max-height: calc(700px - 74px);
}

.date-div{
  border-bottom: 1px solid #22262e;
  padding-left: 0px !important;
  padding-right:13.5px;
  padding-bottom: 10.5px;
  // padding-right: 3rem !important;
  // margin-left: 5px;
  margin-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 70px;
  align-content: flex-end;
  background: inherit;
  transition: background-color .25s ease-out,width .25s ease-out,margin .25s ease-out,padding .25s ease-out;
  -ms-transition: background-color .25s ease-out,width .25s ease-out;
  // padding-top:38px !important;
}
.date-wrapper{
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit;
}

div::-webkit-scrollbar {
    display: block !important;
    width: 10px;
}
div::-webkit-scrollbar-track {
    background-color: #12141a;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #22262e;
    min-height: 40px;
}
.prev{
  cursor: pointer;
  z-index: 999;
}
.tag-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width:1680px) {
  .matches-col{
    flex:0 0 75%;
    max-width:75%;
  }
  .filters-col{
    flex:0 0 25%;
    max-width:25%;
  }

}
@media screen and (min-width:1440px) and (max-width:1680px) {
  .matches-col{
    flex:0 0 70%;
    max-width:70%;
  }
  .filters-col{
    flex:0 0 30%;
    max-width:30%;
  }

}

.no-match-text{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 547px; // total 700px of gray box, we need to subtract date size of past result and then sticky date size also
}
.shecdule-float-btn{
  position: absolute;
  bottom: 5%;
}
#scrolling_div .date-div{
  // padding-top: 31px !important;
  margin-top: 0px !important;
}