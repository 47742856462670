.simple-page-with-tabs {
  h1 {
    margin: 22px 0px;
    margin-bottom: 16px;
    padding: 0px;
  }

  .tabs-container .tab-content {
    padding-top: 36px;
  }

  .transparentButton {
    white-space: nowrap;
  }
}
