.syncGoogle {
  background: white;
  color: #000 !important;
  margin-left: 5px;
  transition: text-shadow 0.3s;
  text-transform: none !important;

  svg {
    float: left;
    margin-left: 0.3rem;
    margin-top: 2px;
    font-size: 22px;
  }
  span {
    position: relative;
    left: -3%;
    vertical-align: -webkit-baseline-middle;
    font-size: 14px;
  }
}
.syncIcalendar {
  background: #1c1c1c;
  color: white !important;
  margin-left: 5px;
  text-transform: none !important;
  transition: text-shadow 0.3s;

  svg {
    float: left;
    margin-left: 0.3rem;
    margin-top: 2px;
    font-size: 22px;
  }
  span {
    position: relative;
    left: -2%;
    font-size: 13px;
    vertical-align: -webkit-baseline-middle;
  }
}

.syncCustom {
  margin-left: 5px;
  transition: text-shadow 0.3s;
  text-transform: none !important;
  background-color: var(--primary);

  svg {
    float: left;
    margin-left: 0.5rem;
    margin-top: 2px;
    font-size: 16px;
  }
  span {
    position: relative;
    left: -3%;
    font-size: 13px;
    vertical-align: -webkit-baseline-middle;
  }
}
