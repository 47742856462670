.edit-image-modal {
  .image-dropzone {
    margin: 20px;
    border: 3px dashed rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    padding: 30px 25px;
    text-align: center;
    color: #999;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1rem;
    font-size: 0.8rem;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.025);
    }
  }

  .edit-image-controls {
    padding: 20px 20px 10px 20px;
    text-align: center;
    display: flex;

    input {
      width: 100%;
      margin: -5px 5px 0 5px;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      font-size: 1.4rem;
      color: white;
      cursor: pointer;
      padding: 0;
      width: 40px;
    }
  }

  .save-buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
}
