// Dynamic search**
.dynamic-search {
  position: relative;
}

.dynamic-search-input {
  position: relative;
  height: 40px;

  .input-icon {
    position: absolute;
    right: 11px;
    top: 11px;
    color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    font-size: 1rem;
  }

  input.form-input {
    background-color: $tab-border-color;
    padding: 10px;
    height: 40px;
    font-size: 1rem;
    line-height: 40px;
    // border-color: 1px solid $event-outline;
    font-size: 0.9rem;
    z-index: 20;

    &:focus,
    &:active {
      // border: 1px solid $event-outline !important;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  input.notSchedule {
    //broke form-input class and separate this for not including in schedule screen

    border-color: 1px solid $event-outline;

    &:focus,
    &:active {
      border: 1px solid $event-outline !important;
    }
  }
}

.dynamic-search-results {
  position: absolute;
  background: $dropdown-bg;
  border: 1px solid $hr-color;
  border-top: 0;
  border-radius: 0 0 2px 2px;
  margin-top: -2px;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  z-index: 10;

  &.hide {
    display: none;
  }

  .search-result {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12.5px;
    cursor: pointer;

    &:hover,
    &.active {
      background: rgba(255, 255, 255, 0.05);
    }

    &:last-child {
      border-bottom: 0;
    }

    &.results-loading {
      justify-content: space-around;
    }

    &.no-results {
      color: #999;
      justify-content: space-around;
      font-size: 0.9rem;
      margin: 7px;
    }

    .search-result-img {
      width: 30px;
      height: 30px;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 100%;
      flex-shrink: 0;
      margin: 0;
    }

    .search-result-img-notCropped {
      width: 30px;
      height: 30px;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 0% !important;
      flex-shrink: 0;
    }

    .search-result-details {
      width: calc(100% - 42px);
      margin-left: 12px;

      .flex-sb {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .flex-normal {
        display: flex;
        align-items: center;
      }

      .title-subtitle {
        p {
          font-size: 0.95rem;
        }

        small {
          font-size: 0.75rem;
          margin: 0;
        }
      }

      .title,
      .subtitle {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        margin: 0 0 3px 0;
        font-size: 0.95em;
        margin: 5px 0 1px 0 !important;
      }

      .subtitle {
        margin: 0px 0 0 10px;
        font-size: 0.8rem;
        color: #999;
      }

      .type {
        flex-shrink: 0;
        justify-self: flex-end;
        background: #22262e;
        color: white;
        font-size: 0.75rem;
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 100px;
        text-transform: uppercase;
      }
    }
  }
}

.dynamic-search-selected-items {
  margin: 15px 0;
  height: 250px;
  overflow-y: scroll;

  .selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .item-details {
      display: flex;
      align-items: center;
      width: calc(100% - 30px);

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item-img {
      flex-shrink: 0;
      width: 33px;
      height: 33px;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 100%;
      margin-right: 12px;
    }

    .item-user-name {
      font-size: 0.9rem;
      color: #999;
      margin-left: 10px;
    }

    .item-name-multi {
      p {
        font-size: 0.9rem;
        font-weight: bold;
        margin: 5px 0 1px 0;
        color: white;
      }

      small {
        margin: 0;
        color: #68717e;
      }
    }

    select.form-input {
      width: 175px;
      margin-right: 10px;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      color: #777;
      width: auto;
      padding: 5px;
      margin: 0;
      cursor: pointer;
    }
  }
}

.redirect-link {
  text-decoration: none;
}
