.slider-container {
  display: flex;
  padding: 0px 0px 36px 0px;

  .slider-info {
    padding-right: 75px;
    min-width: 21%;

    h1 {
      margin: 0px;
      padding-bottom: 6px;
    }

    .button {
      margin: 16px 0px 26px;
      margin-left: 5px;
    }
  }

  .slider-items {
    flex: 1;
    display: block;

    .item {
      padding-right: 12px;
      padding-left: 12px;
      padding-bottom: 0px;
      min-width: 400px;
    }
    &.smallSliderGameSelection {
      position: relative;
      .item {
        padding: 3px;
      }
    }
  }

  .slick-arrow {
    color: var(--primary);
    border-radius: 100%;
    // width: 45px;
    // height: 45px;
    z-index: 50;
    display: block;
    top: 60px;
    transform: inherit;
    &.slick-prev {
      left: -4px;
      font-weight: 900;

      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f053";
        font-size: 22px !important;
        color: var(--primary);
        margin-right: 4px;

      }
    }

    &.slick-next {
      right: -4px;
      font-weight: 900;

      &::before {
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        margin-left: 4px;
        font-size: 22px !important;
        color: var(--primary);
      }
    }

    &.slick-next:hover,
    &.slick-prev:hover {

      opacity: 1;
    }

    &.slick-next.slick-disabled,
    &.slick-prev.slick-disabled {
      opacity: 0;
    }
  }

  .slick-slider {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    &::after {
      position: absolute;
      content: "";
      width: 70px;
      height: 100%;
      // background: linear-gradient(to right, transparent, $content-bg-color 130%);
      right: 0px;
      top: 0px;
    }
  }
}

.gameSelection {
  .slick-list {
    // background-color: rgb(196, 194, 194);
    width: 94%;
    // margin: auto;
  }
}
