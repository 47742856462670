.overview {
  .image-text {
    display: flex;

    img {
      margin-right: 20px;
      width: 120px;
    }

    .text-32 {
      font-size: 26px;
      margin: 0px;
      padding: 10px 0px;
      line-height: 30px;
      margin-top: 20px;
    }

    .text-14 {
      font-size: 14px;
      line-height: 1.43;
      color: #68717e;
    }
  }

  .league-country-icon {
    display: inline-block;
    vertical-align: middle;
  }

  .title {
    display: block;
    width: 100%;
  }

  .card-container {
    margin-top: 10px;
    .card {
      .overlay {
        display: none;
      }
      padding: 5px 0px;
      .text {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .uppercase,
        span {
          margin: 0px;
          line-height: 1;
          display: inline-block;
        }
        .uppercase {
          text-align: right;
          font-size: 12px;
          width: 60%;
        }
        span.faded {
          width: 40%;
        }
      }
    }
  }
}
