.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 22px 0px 66px;

	.prev-button,
	.next-button,
	.pagination-link {
		margin: 0px 6px;
		cursor: pointer;
		background-color: transparent;
		border: none;

		&.disabled {
			opacity: 0.25;
		}

		&:active,
		&:focus {
			outline: none;
		}

		.icon {
			display: block;
			margin-top: 6px;
		}
		svg{
			color: #fff !important;
			display: block;
			margin-top: 5px;
		}
	}

	.fitstPageButton{
		margin-top: -3px;
		margin-right: 12px;
		cursor: pointer;
		background-color: transparent;
		border: none;

		&.disabled {
			opacity: 0.25;
		}

		&:active,
		&:focus {
			outline: none;
		}
		svg{
			color: #fff !important;
			display: block;
			margin-top: 5px;
		}
	}

	.lastPageButton{
		margin-top: -3px;
		cursor: pointer;
		background-color: transparent;
		border: none;

		&.disabled {
			opacity: 0.25;
		}

		&:active,
		&:focus {
			outline: none;
		}
		svg{
			color: #fff !important;
			display: block;
			margin-top: 5px;
		}
	}

	.pagination-link {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		font-size: 12px;
		line-height: 15px;
		font-weight: bold;
		border-radius: 16px;

		&.active {
			background-color: transparentize($primary, 0.9);
		}
	}
}

.pageStats{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -55px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 25px;
}
