@import "./PlayerProfileTabs/player-profile-tabs";

// Player header
.player-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px;
  min-height: 135px;

  .player-name {
    h2 {
      font-size: 2rem;
      line-height: 1.5rem;
      margin: 0px;
    }

    h4 {
      margin: 0px;
      margin-top: 16px;
      font-weight: normal;
    }
  }

  .player-pill {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: -25px;
    background: #777;
    color: white;
    margin-left: 10px;
  }

  .player-header-images {
    display: flex;
    align-items: center;

    img.game-icon {
      width: 35px;
      height: 35px;
      object-fit: contain;
      margin-right: 28px;
    }
  }

  .player-avatar {
    width: 111px;
    height: 111px;
    line-height: 111px;
  }

  .player-profile-image {
    width: 111px;
    height: 111px;
    border-radius: 100%;
    object-fit: cover;
  }
}

.player-avatar {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  background: transparentize($primary, 0.9);
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 130px;
  border-radius: 100%;
}

.player-avatar-csgo {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  background: transparentize($accent-red, 0.9);
  color: var(--primary);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 130px;
  border-radius: 100%;
}

.player-overview-row {
  .social-links {
    margin: 20px 0px;

    img {
      width: 46px;
      height: 46px;
      margin-right: 10px;
    }
  }
}

.advert-with-top-margin {
  margin-top: 20%;
}
