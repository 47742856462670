.delete-account-text {
  font-size: 0.8rem;
  line-height: 1.1rem;

  p {
    margin-top: 0;
  }

  .to-continue-text {
    margin-top: 30px;
  }
}
