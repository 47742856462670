.chat-room-container {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    height: 100%;
}

.room-name {
    margin-top: 0;
}

.messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
    // border: 1px solid black;
    // border-radius: 7px 7px 0 0;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

textarea.new-message-input-field { font-size: 15px; }

.new-message-input-field {
    width: 100%;
    height: 200px;
    max-height: 50%;
    font-size: 20px;
    padding: 12px 12px;
    resize: none;
    background-color: inherit;
    color: inherit;
    margin-top: 30px;
    outline: none;
    transition: border 0.3s;
}
.new-message-input-field:focus {
    border: 1px solid var(--primary)
}

.message-item {
    width: 55%;
    margin: 8px;
    padding: 12px 8px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}
.message-card {
    border-bottom: 1px solid #22262e
}
.message-card:last-child {
    border-bottom: none;
}

.messageContent {
    padding: 12px 0px;
}

.noBorder {
    border-bottom: none;
    padding: 0
}
.noPadding {
    padding: 0
}

.my-message {
    background-color: var(--primary);
    margin-left: auto;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
}

.messages-container, .new-message-input-field, .send-message-button {
    border-color: 1px solid #22262E;
    max-height: 50%;
}

.textAreaPlusButton {
    position: 'fixed';
}
.postButton{
    float: right;
}
.chat-avatar { 
    background: transparentize($primary, 0.9);
    color: var(--primary);
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    text-transform: uppercase;
  }