.tournament-list-item {
  border-radius: 2px;
  margin-bottom: 6px;
  padding: 12px 0px;

  &.bg-success.bordered {
    border-left: 5px solid $success;
  }

  &.bg-danger.bordered {
    border-left: 5px solid $danger;
  }

  &.bg-secondary.bordered {
    border-left: 5px solid #777;
  }

  .imgCrop{ 
    width: 15px;
    height: 15px;
    margin-right: 5px; 
    border-radius: 100%;
    vertical-align: middle;
  }

  .imgNotCrop{ 
    width: 15px;
    height: 15px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .col-4,
  .col-3,
  .col-5,
  .col-2 {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  .tournament-list-item-first-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0 20px;

    > span {
      margin-bottom: 5px;
    }

    > div {
      margin-bottom: 2px;
      display: flex;
    }
  }

  .tournament-list-item-second-col {
    display: flex;
    align-items: space-around;
    justify-content: center;

    .text-13 {
      margin-bottom: 5px;
    }

    .text-10 {
      margin: 3px 0;
    }
  }

  .tournament-list-item-third-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .tournament-list-item-fourth-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: none;
    padding: 0 20px;

    .players {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: calc(4px * 1 + 6rem);
      align-items: center;
      width: 155px;
    }

    .tournament-player {
      width: 45%;
      height: 20px;
      display: flex;
      align-items: center;
      flex-shrink: none;
      font-size: 0.8rem;
      padding: 2px 0px;

      img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border-radius: 2px;
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.65rem;
      }
    }
  }

  .tournament-images-container {
    display: flex;
    align-items: center;

    img {
      //margin-right: 12px;
    }

    > div {
      flex-direction: column;
    }

    div {
      display: flex;
      justify-content: space-around;
      align-items: space-around;
    }

    .round-image {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      object-fit: cover;
      margin-right: 7px;
    }

    .small-image {
      width: 27px;
      height: 27px;
      object-fit: cover;
      border-radius: 1px;
      margin: 2px;
    }
  }

  .tournament-item-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.data-kda {
      width: 130px;
      text-align: center;
    }
  }

  .special-kill-pill {
    margin: 4px 0 0 0;
  }
}

.tournament-list-item-expandable-content {
  display: none;

  &.expanded {
    display: block;
  }

  .player-image {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }

  .tournament-detail-header {
    padding: 0 15px 10px 15px;
  }

  .damage-meter {
    margin: 6px auto;
    width: 90%;
    height: 8px;
    background-color: $text-faded-color;
    position: relative;

    .damage-bar {
      background-color: $danger;
      left: 0px;
      top: 0px;
      position: absolute;
      width: 70%;
      height: 100%;
    }
  }

  .items-row {
    display: flex;
    flex-wrap: wrap;
  }
}

.tournament-list-item-item {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 1.5px 3px;
  border-radius: 3px;
  background-color: #777;
  background-size: cover;
}
