.registration-welcome {
	text-align: center;
	margin: 50px auto;
	width: 100%;
	max-width: 420px;
	border-radius: 2px;
	background-color: $bg-faded;

	h1 {
		margin: 0px;
		padding: 14px 0px;
	}

	hr {
		margin: 0px;
	}
	.registration-welcome-inner {
		padding: 22px;
	}

	.button-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 20px;
	}

	.account-type-item {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $dark-bg-color;
		border-radius: 2px;
		margin: 7px 0px;
		padding: 12px;
		border: 1px solid transparent;
		transition: border 0.3s;
		cursor: pointer;

		&:hover {
			border: 1px solid var(--primary);
		}
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 23px;
			width: 46px;
			height: 46px;
			flex-shrink: 0;
			background-color: $bg-faded;
		}

		.account-type-item-text {
			padding-left: 12px;

			.text-10 {
				line-height: 16px;
				padding-top: 3px;
			}
		}
	}
}
