.simple__tabs__container {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
  // overflow-x: scroll;
    .sub-tabs {
      width: 100%;
      scroll-behavior: smooth;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 3.5px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: background-color 3s;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--primary);
      }
      nav {
        display: flex;
        margin-left: 2px;
        padding-bottom: 5px;
        button {
          min-width: max-content;
          white-space: nowrap;
        }
        position: relative;
        .ellipses-btn {
          position: absolute;
          right: 0;
          btn {
            margin-right: 0;
          }
        }
      }
      button {
        min-width: max-content;
        white-space: nowrap;
    }
  }
}
