.auth-modal {
  .modal-step-progress {
    position: absolute;
    left: 0px;
    top: -7px;
    transform: translateY(-100%);
  }

  .code-incorrect-message {
    border: 1px solid $danger;
    border-radius: 5px;
    padding: 11px 13px;
    margin-bottom: 16px;

    div.text-12 {
      padding-bottom: 4px;
    }
  }
}

.forgot-password-back-wrapper {
  button {
    cursor: pointer;
  }
  .icon img {
    transform: rotate(90deg);
    margin-bottom: 1px;
  }
}

.login-register-modal .modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 240px);
}

.modal .modal-body.verify-email {
  a,
  .auth-resend-code {
    color: var(--primary);
  }
}

.modal .modal-header.verify-email {
  h2 {
    text-align: left;

    &:nth-child(2) {
      padding-top: 0px;
    }
  }

  .text {
    font-size: 12px;
    line-height: 18px;
    color: $white;
    font-weight: normal;
    text-align: left;
  }
}

.modal .modal-header.verify-email,
.modal .modal-header.accept-terms {
  position: relative;

  .modal-back-button {
    background-color: transparent;
    border: none;
    color: $white;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    font-size: 22px;
  }
}

.modal .modal-body.accept-terms::-webkit-scrollbar {
  width: 0 !important;
}

.modal .modal-body.accept-terms {
  padding: 0px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;

  .modal-inner {
    padding: 20px;

    label {
      font-size: 12px;
      line-height: 15px;
      font-weight: normal;
      text-transform: none;
    }
  }
  .auth-terms-container {
    position: relative;
    font-size: 0.8rem;
    line-height: 1.5rem;
    margin-bottom: 0px;
    padding-bottom: 0px;

    .terms-inner {
      position: relative;
      max-height: 400px;
      overflow-y: scroll;
      padding: 20px;
    }

    &:before,
    &:after {
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
      height: 30px;
      // background: white;
      z-index: 99999;
    }

    &:before {
      top: 0;
      box-shadow: inset 0 22.5px 25px $modal-bg-color;
    }

    &::after {
      bottom: 0;
      box-shadow: inset 0 -22.5px 25px $modal-bg-color;
    }

    h1 {
      font-size: 10px;
      margin: 0px;
      padding: 0px;
      line-height: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      margin-top: 0px;
    }
  }
}

.modal-body, .modal {
  .form-input {
    background-color: $tab-border-color;
    border-radius: 2px;
    font-size: 12px;

    &:focus {
      background-color: $header-bg-color;
      border: 1px solid var(--primary);
    }
  }

  label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
  }

  select {
    background-color: $tab-border-color;
    border-radius: 2px;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }
}

.auth-modal-header {
  text-align: center;
  padding: 20px;
  padding-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 12px;
    height: 36px;
  }

  h2 {
    font-size: 16px;
  }
}

.auth-modal-tabs {
  border-bottom: 1px solid $tab-border-color;
  padding: 0 20px;

  .tab {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    padding: 10px 10px 17.5px 10px;
    margin-right: 26px;
    cursor: pointer;
    color: #68717e;
    transition: all 0.2s ease-in-out;

    &.active {
      border-bottom: 2px solid var(--primary);
      color: white;
    }

    &.disabledTab {
      cursor: zoom-out;

      &:hover {
        color: #68717e;
      }
    }

    &:hover {
      color: white;
    }
  }
}

.forgot-password {
  display: block;
  font-size: 12px;
  margin: 8px 0px 15px;
  color: var(--primary);
  text-decoration: none;
}

.auth-verification-code-input {
  width: calc(100% + 20px) !important;
  margin: -10px;

  &.invalid {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    input[type="tel"] {
      border: 1px solid $danger !important;
    }
  }

  input[type="tel"] {
    width: calc(16.666666667% - 20px) !important;
    margin: 10px;
    border-radius: 5px !important;
    background-color: $dark-bg-color !important;
    border: 1px solid transparent !important;
    font-family: $font-stack;
    font-size: 1.8rem;
    color: white;
    caret-color: inherit !important;
    transition: background-color 0.1s, border 0.1s;

    &:focus {
      // background-color: #000 !important;
      border: 1px solid var(--primary) !important;
    }
  }

  .styles_loading__Z65VQ {
    line-height: 73px !important;
  }

  .styles_blur__19vMK {
    line-height: 68px;
    background-color: #141414;
    opacity: 0.8;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.auth-resend-code {
  appearance: none;
  background: none;
  border: 0;
  outline: 0;
  color: inherit;
  padding: 0;
  margin: 20px 0 0 0;
  font-size: 0.9rem;
  cursor: pointer;
}
