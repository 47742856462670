.teams__category_title {
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  margin-bottom: -3px;
  margin-top: -23px;
  // margin-bottom: -12px;
  // margin-top: 8px;
  align-items: center;

  .teams__category_title_button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // position: absolute;
    // right: 0;
    // // top: 14%;
    // top: 10%;

    .button {
      height: 37px;
      margin: 14px 5px 0px 5px;
    }

    .teams__category_title_button-primary {
      background-color: var(--primary);
    }

    .teams__category_title_button-danger {
      background-color: #fd1049;
    }
  }
}

.teams__category_title {
  h3 {
    font-size: 23px;
    margin: 0;
    padding-top: 30px;
  }
}

.teams__create_category {
  background-color: #1adb60 !important;
  height: 37px !important;
  position: absolute;
  right: 27px;
  top: -11px;
  // top: 7.7px
}
