.global-header {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: $header-bg-color;
  padding: 12px;
  color: white;
  border-bottom: 1px solid $header-sidebar-border-color;
  z-index: 999;
  height: 60px;
  box-shadow: 1px 3px 5px black;
  .dynamic-search-input {
    width: 460px;

    input {
      background: $tab-border-color;
      border: 1px solid transparent !important;
    }
  }

  .header-game-switcher-bleft {
    border-left: 1px solid $border-color;
    margin-left: 25px;
    height: 30px;
  }

  .header-game-switcher {
    margin-left: 18px;
    padding-left: 10px;
    // border-left: 1px solid $border-color;
    width: 150px;
    justify-content: flex-start !important;

    .dropdown-content main .game-switcher-link {
      width: 180px;
      padding-left: 12px;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.3s, color 0.3s;
      display: flex;
      align-items: center;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        background-size: cover;
      }

      &:hover {
        opacity: 1;
        color: var(--primary);
      }

      &:active {
        .dropdown-toggle {
          background-color: rgb(25, 27, 36);
        }
      }
    }

    .dropdown-toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;

      img.switcher-game-logo {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      .icon img {
        margin-left: 8px;
      }
    }
  }

  .header-logo {
    display: flex;
    align-items: center;
    width: 467px;

    .menu-icon {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      color: white;
      font-size: 1.5rem;
      width: 60px;
      height: 60px;
      margin-left: -12px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
    }

    a.logo-text {
      font-size: 1.05rem;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center;

      .logo-glitch {
        div {
          align-items: center;
          display: flex;
          width: min-content;
        }
      }

      img {
        margin-left: 4px;
        margin-right: 8px;
        height: 30px;
        width: auto;
      }
    }
    .logo__text {
      position: relative;
      top: -7px;
    }
  }

  .header-search {
  }

  .header-actions {
    display: flex;
    align-items: center;
    width: 367px;

    justify-content: flex-end;

    .dropdown-wrapper:last-child {
      margin-right: 12px;
    }

    .header-settings-link {
      width: 100%;
      padding: 12px 12px;
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      display: flex;
      .icon img {
        margin-right: 12px;
      }
    }
    .header-action-btn {
      display: inline-block;
      color: white;
      margin: 8px 8px 8px 15px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .header-profile-link {
    text-decoration: none;
    margin-right: 11px;
    font-size: 0.8rem;
    line-height: 15px;

    img {
      margin-right: 7px;
      border-radius: 2px;
      vertical-align: middle;
      height: 24px;
      width: 24px;
      object-fit: cover;
    }
  }

  @import "./NotificationsDropdown/notifications-dropdown";
  @import "./Dropdown/dropdown";
}

.upcoming-match {
  position: fixed;
  width: 400px;
  bottom: 20px;
  right: 20px;
  background: black;
  padding: 20px;
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}
