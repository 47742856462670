.calendarDetailModal {
  a {
    color: var(--primary);
  }

  .headerICon {
    margin-right: 10px;
  }

  .link {
    color: white;
    font-size: 14px !important;
    margin-bottom: 5px !important;
    line-height: 18px !important;
  }

  p {
    color: #68717e;
    font-weight: 500;
    line-height: 1.3rem;
  }

  .syncDescription {
    img {
      width: 100%;
    }
  }
}
