.matches-header-inner {
  .image-cropped {
    border-radius: 100% !important; //Added once again because it imapct all over the pages
    width: 30%;
    max-width: 10vw;
    object-fit: contain;
    height: auto;
    max-height: 100px;
    // border-radius: 100%;
  }

  .image-notCropped {
    width: 25%;
    object-fit: contain;
    height: auto;
    max-height: 100px;

    // border-radius: 100%;
  }
}
