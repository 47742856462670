.news-single {
  .news-featured-image-wrapper {
    width: 100%;
    height: 250px;
    overflow: visible;

    .news-featured-image {
      width: 100%;
      height: 400px;
      background-size: cover;
      background-position: center;
    }
  }

  .twitter-tweet-rendered {
    margin-left: 10.5%;
  }

  .news-single-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .news-single-content,
    .news-single-content-after {
      width: 100%;
      max-width: 800px;
      margin: 0px auto;
    }

    li {
      line-height: 2.1;
      font-size: 18px;
    }

    .news-single-content {
      margin: 55px auto 25px;

      //Font styling here
      p,
      a {
        font-size: 18px;
        line-height: 1.44;
      }
      a {
        text-decoration: underline;
        color: var(--primary);
        font-weight: bold;
      }
      p {
        margin-bottom: 40px;
        line-height: 2.1;
      }

      hr {
        margin: 48px 0px !important;
      }

      h1,
      h2,
      h3,
      h4 {
        margin-top: 28px;
        margin-bottom: 10px;
        line-height: 2.1;
      }

      blockquote {
        line-height: 2.1;
      }
    }
  }

  h1.news-heading {
    //width: 80%;
    max-width: 600px;
    line-height: 1.44;
    margin-bottom: 0px;
  }

  .author {
    margin: 22px 0px;
    text-transform: uppercase;
    font-weight: bold;

    img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      object-fit: cover;
      margin-right: 12px;
    }

    .article-by {
      display: block;
      margin-bottom: 10px;
      color: $text-faded-color;
    }

    .author-info {
      display: flex;
      align-items: center;
    }

    .author-name-bio {
      display: flex;
      flex-direction: column;

      span {
        display: block;
        margin: 1px 0px;
      }
    }
  }

  .social-follow-container {
    margin: 60px auto 54px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-faded {
      text-transform: uppercase;
      margin-bottom: 18px;
    }

    .social-links {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin: 0px 5px;

        img {
          width: 46px;
          height: 46px;
          object-fit: cover;
        }
      }
    }
  }
}

.cool-heading-container {
  position: relative;

  h1 {
    //padding: 46px 0px;
    font-size: 52px;
    width: 100%;
    position: relative;
    margin: 0px;

    .news-heading-background {
      opacity: 0.03;
      -webkit-text-stroke: 3px #ffffff;
      font-family: Montserrat;
      font-size: 197px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: 15.76px;
      text-align: left;
      color: #3eabf0;
    }

    .news-heading-foreground {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dot-pattern {
    left: -35px;
    top: -50px;
  }
  .icon-cross {
    left: 150px;
    top: 50px;
    position: absolute;
  }
  .icon-play {
    position: absolute;
    right: 90px;
    bottom: 50px;
    transform: rotate(90deg);
  }
}

.dot-pattern {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 120px;
  span {
    width: calc(100% / 12);
    border-radius: 3px;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
    width: 3px;
    height: 3px;
    background-color: transparentize($text-faded-color, 0.78);
  }
}

.scroll-thing-major {
  perspective: 1px;
  // bottom: 500%;
  height: 270vh;
  // height: 100%;
  z-index: 0;
  position: absolute;
  right: 200px;
  top: 0px;
  //height: 80%;
  width: 1px;
  background-color: $border-color;

  .thing,
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 6px;
    height: 24px;

    //margin-left: -3px;
    //margin-top: 100px;
    transform: translateZ(-1px) scale(2);
  }
  .thing {
    background-color: var(--primary);
    //   top: -36%;
  }
  &.box {
    top: 10%;
  }
  &.cross {
    right: auto;
    left: 225px;
    //top: 200px;
    .icon {
      color: var(--primary);
      left: -10px;
      top: 5%;
    }
  }
  &.play {
    right: auto;
    left: 80px;
    .icon {
      left: 5px;
      top: 25%;
    }
  }
}

.moreUpdate {
  margin-right: 8px;
}
/*twitter-box*/
// .new-box{
//   background-color: #ffffff;
//   color: #000;
//   width: 50% !important;
//   padding: 15px;

//   .new-box-inner{
//     border: 1px solid #80808052;
//     border-radius: 20px;
//     padding: 20px;
//   }
//   .box-header{
//   display: flex;
//   }
//   .new-box-twitter{
//   position: absolute;
//     right: 29%;
//     svg{
//       background: #000;
//       font-size: 40px;
//       padding: 10px;
//       border-radius: 20px;
//     }
//   }
//   .box-flex{
//     display: flex;
//   }
//   .new-box-image{
//   background: black;
//     border-radius: 100px;
//     padding: 10px;
//   }
//   .new-ibox-image-text{
//     padding-top: 20px;
//     margin-right: 10px;
//   }
//   .kg-card{
//   margin: 0px;

//     .twitter-tweet{
//     margin: 0px;
//     }
//   }

//}
