.team-stat-item {
  font-size: 12px;
  line-height: 15px;
  border: 1px solid $tab-border-color;
  border-radius: 2px;

  .recently-played-stat {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 6px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    span {
      display: flex;
      justify-content: center;
      padding: 3px 0px;
      img {
        margin-right: 4px;
      }
    }
  }

  .team-stat-item-inner {
    padding: 15px;

    &.player-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .player-info-name {
        margin-bottom: 4px;
      }

      .player-info-logo,
      .player-info-rank,
      .player-info-wl-ratio-container {
        margin-bottom: 12px;
      }
      .player-info-logo {
        padding-top: 3px;

        .role-icon {
          width: 20px;
          height: 20px;
        }
      }

      .player-info-rank {
        padding-bottom: 4px;
      }

      .player-info-wl-ratio-container {
        width: 100%;
        border-radius: 2px;
        height: 25px;
        display: flex;
        overflow: hidden;

        .player-info-w,
        .player-info-l {
          height: 100%;
          line-height: 25px;
          text-weight: bold;
          padding: 0px 13px 0px 7px;
        }

        .player-info-w {
          width: 70%;
          background-color: $success;
          text-align: left;
        }

        .player-info-l {
          width: 30%;
          background-color: $danger;
          text-align: right;
        }
      }

      .player-info-logo {
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
        }

        img.player-logo {
          width: 32px;
          height: 32px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 16px;
        }
      }

      .win-rate {
        padding: 6px 0px;
      }
    }

    .success,
    .danger {
      line-height: 24px;
      border-radius: 2px;
      color: $success;
      width: 80px;
      text-align: center;
      background-color: transparentize($success, 0.8);
      height: 100%;
    }

    .danger {
      color: $danger;
      background-color: transparentize($danger, 0.8);
    }

    .team-stat-heading {
      font-size: 12px;
      line-height: 15px;
      padding-bottom: 10px;
    }

    .team-stat-list-item {
      display: flex;
      margin: 7px 0px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        margin-right: 6px;
      }

      .team-stat-list-subitem {
        display: flex;
        flex-direction: column;

        &.team-stat-list-subitem-center,
        .team-stat-list-subitem-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          font-size: 12px;
          line-height: 15px;
        }

        &.flex-expand {
          flex: 1;
        }

        &:not(.flex-expand) {
          margin-right: 6px;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  hr {
    margin: 0px;
  }
}
