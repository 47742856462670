// :root {
//   --steps: 20;
// }
// @keyframes glitch-animation-1 {
//   $steps: 20;
//   @for $i from 0 through $steps {
//     #{percentage($i*(1/$steps))} {
//       clip: rect(random(150) + px, 350px, random(150) + px, 30px);
//     }
//   }
// }
// @keyframes glitch-animation-2 {
//   $steps: 20;
//   @for $i from 0 through $steps {
//     #{percentage($i*(1/$steps))} {
//       clip: rect(random(150) + px, 350px, random(150) + px, 30px);
//     }
//   }
// }
// .wrapper {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .glitch__text {
//     color: white;
//     font-family: sans-serif;
//     font-weight: 800;
//     position: relative;
//     font-size: 100px;
//     padding: 30px;
//   }

//   .glitch__text::before,
//   .glitch__text::after {
//     padding: 30px;
//     color: white;
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: black;
//     overflow: hidden;
//     top: 0;
//   }

//   .glitch__text::before {
//     left: 3px;
//     text-shadow: -2px 0 red;
//     animation-name: glitch-animation-1;
//     animation-duration: 2s;
//     animation-timing-function: linear;
//     animation-delay: 0s;
//     animation-iteration-count: infinite;
//     animation-direction: reverse-alternate;
//   }

//   .glitch__text::after {
//     left: -3px;
//     text-shadow: -2px 0 blue;
//     animation-name: glitch-animation-2;
//     animation-duration: 2s;
//     animation-timing-function: linear;
//     animation-delay: 0s;
//     animation-iteration-count: infinite;
//     animation-direction: reverse-alternate;
//   }
// }

// body {
//   background: repeating-linear-gradient(0deg, #0e0d0e 25%, #0e0d0e 50%, #171819 50%, #171819 75%);
//   background-size: 10px 10px;
//   height: 100vh;
//   overflow: hidden;
//   display: flex;
//   font-family: "Barlow", sans-serif;
//   justify-content: center;
//   align-items: center;
// }

.wrapper {
  position: relative;
}

.line {
  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation: clip 3000ms $i * -300ms linear infinite, glitch#{$i} 500ms random(1000) * -1ms linear infinite;

      @keyframes glitch#{$i} {
        0% {
          transform: translateX(0);
        }
        80% {
          transform: translateX(0);
          color: #fff;
        }
        85% {
          transform: translateX(random(10) - 5px);
          color: #4e9a26;
        }
        90% {
          transform: translateX(random(10) - 5px);
          color: #ac1212;
        }
        95% {
          transform: translateX(random(10) - 5px);
          color: #fff;
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
}

.wrapper {
  .line__hover {
    &:not(:first-child) {
      display: none;
    }
  }
  &:hover {
    .line__hover {
      &:nth-child(n) {
        display: block;
      }
      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
      }

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation: clip 3000ms $i * -300ms linear infinite, glitch#{$i} 500ms random(1000) * -1ms linear infinite;

          @keyframes glitch#{$i} {
            0% {
              transform: translateX(0);
            }
            80% {
              transform: translateX(0);
              color: #fff;
            }
            85% {
              transform: translateX(random(10) - 5px);
              color: #4e9a26;
            }
            90% {
              transform: translateX(random(10) - 5px);
              color: #ac1212;
            }
            95% {
              transform: translateX(random(10) - 5px);
              color: #fff;
            }
            100% {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}

@keyframes clip {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
  }

  100% {
    clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
  }
}
