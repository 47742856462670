.card-container {
  overflow: hidden;

  .card {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid $tab-border-color;
    background-color: $content-bg-color;

    display: flex;
    align-items: center;

    &.corner-outline {
      .icon img {
        height: 18px;
        // object-fit: contain;
      }
      .overlay {
        position: absolute;
        width: 50px;
        height: 50px;
        border-top: 1px solid $tab-border-color;
        background-color: $content-bg-color;
        transform: rotate(-45deg);
        right: -25px;
        bottom: -25px;
      }
      .text {
        width: auto;
        margin-left: 20px;
      }
      h4 {
        margin: 0px;
        padding: 8px 0px;

        font-size: 14px;
        line-height: 18px;
        padding-bottom: 5px;
      }
      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
