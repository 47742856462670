input[type="time"],
input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.time, .date {
  padding: 10.5px 13.5px !important;
}

.date {
  margin-top: 21px;
}
