img.wide-page-with-tabs-logo {
  width: 104px;
  height: 104px;
  margin-right: 16px;
  object-fit: contain;
  border-radius: 100%;
}

img.wide-page-with-tabs-logo_withoutCropped {
  width: 104px;
  height: 104px;
  margin-right: 16px;
  object-fit: contain;
}

.teamimage {
  .item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      &.teamicon {
        padding: 0 !important;
        margin: 0 !important;
        img {
          margin: 0 !important;
        }
      }
    }
  }
}
.teams-item {
  .image-cover {
    //display: none;
  }

  .item-image {
    .image-logo {
      height: 75px;
      width: 75px;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;
      z-index: 2;
      border-radius: 100%;
    }
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
    span {
      display: block;

      &.showTag {
        display: block;
      }
    }
  }

  .item-info {
    display: none;
  }

  .item-excerpt {
    img {
      vertical-align: middle;
      margin-right: 4px;
      height: 14px;
      width: 14px;
    }
  }
}
