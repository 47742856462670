@import "./HomeHero/home-hero";
@import "./Slider/slider";
@import "./Slider/arrows";
// @import "./Slider/arrowsBg";

.home-hero-container {
  position: relative;
  // height: 630px;
  overflow: hidden;

  .dot-pattern {
    right: -10px;
    top: 280px;
  }
}

.cool-heading-container {
  position: relative;

  h1 {
    //padding: 46px 0px;
    font-size: 52px;
    width: 100%;
    position: relative;
    margin: 0px;

    .news-heading-background {
      opacity: 0.03;
      -webkit-text-stroke: 3px #ffffff;
      font-family: Montserrat;
      font-size: 197px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: 15.76px;
      text-align: left;
      color: #3eabf0;
    }

    .news-heading-foreground {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dot-pattern {
    left: -35px;
    top: -50px;
  }
  .icon-cross {
    left: 150px;
    top: 50px;
    position: absolute;
  }
  .icon-play {
    position: absolute;
    right: 90px;
    bottom: 50px;
    transform: rotate(90deg);
  }
}

.dot-pattern {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 120px;
  span {
    width: calc(100% / 12);
    border-radius: 3px;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
    width: 3px;
    height: 3px;
    background-color: transparentize($text-faded-color, 0.78);
  }
}

.scroll-thing {
  perspective: 1px;
  // height: 200vh;
  height: 100%;
  z-index: -1;
  position: absolute;
  right: 200px;
  top: 0px;
  //height: 80%;
  width: 1px;
  background-color: $border-color;

  .thing,
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 6px;
    height: 24px;

    //margin-left: -3px;
    //margin-top: 100px;
    transform: translateZ(-1px) scale(2);
  }
  .thing {
    background-color: var(--primary);
    top: -36%;
  }
  &.box {
    //top: -100px;
  }
  &.cross {
    right: auto;
    left: 225px;
    //top: 200px;
    .icon {
      left: -20px;
      top: -5%;
    }
  }
  &.play {
    right: auto;
    left: 80px;
    .icon {
      left: -5px;
      top: -25%;
    }
  }
}

.image-with-content-area {
  width: 82%;
  margin: auto;
  .image-section {
    height: 321px;
    width: 530px;
  }
  .content-section {
    .content-inner {
      padding: 40px;
      .subheading {
        color: var(--unnamed-color-3eabf0);
        text-align: left;
        font: normal normal bold 14px/18px Montserrat;
        letter-spacing: 0px;
        color: #3eabf0;
        text-transform: uppercase;
        opacity: 1;
      }
      .heading {
        text-align: left;
        font: normal normal bold 32px/39px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
        margin: 5px 0;
      }
      .para {
        text-align: left;
        font: normal normal normal 12px/18px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}

.imageWithContentSection {
  padding: 40px 0px 40px 0px;
}

.card-pricing.popular {
  z-index: 1;
  border: 3px solid #007bff;
}
.card-pricing .list-unstyled li {
  padding: 0.5rem 0;
  color: #6c757d;
}
.card-pricing {
  width: 419px;
  text-align: left !important;
  height: 535px;
  background: #22262e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;

  &.prouser {
    height: 230px;
  }
}
.status {
  background: var(--primary) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.planPrice {
  color: var(--primary);
  text-align: left;
  font: normal normal bold 16px/19px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

.price-desc {
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  color: #9ba8ba;
  opacity: 1;
}

.featureListing > li {
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  list-style: none;
}

.featureListingSingle {
  color: var(--primary);
}

.floatright {
  float: right;
}

.trailNote {
  text-align: center;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  color: #9ba8ba;
}

.gameSelection {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 86px 0px;
  position: relative;
  overflow-x: hidden;

  .bar {
    perspective: 1px;

    z-index: -1;
    position: absolute;
    // padding-right:10%;
    top: 28%;
    height: 2px;
    // height: 200vh;
    width: 100%;
    background-color: var(--primary);

    &.withwheel {
      top: 30%;
    }
    &.withoutwheel {
      top: 50%;
    }
    &.sliderwithwheel {
      // top: 78.8%;//////
      top: 68px;
      bottom: 0;
      left: 4px;
      right: 4px;
      width: auto;
      // width: 940px;/////
      // position: inherit;
    }
    &.sliderwithoutwheel {
      display: none;
    }
  }
  .row {
    max-width: 950px;
    //margin: 60px;
    justify-content: center;
    margin-bottom: 58px;
  }
}

.upcomingMatchesManual,
.UpcomingMatchesSeason {
  .filters {
    width: 16%;

    .form-input {
      border-radius: 2px;
      opacity: 1;

      &:focus {
        background-color: #22262e;
        border: 1px solid var(--primary);
      }

      &:hover{
        border: 1px solid var(--primary);
      }
    }

    .matchesDropdown {
      background-color: transparent;
      border: 1px solid #22262e;
      border-radius: 2px;
    }
  }
}
.notLikeButton {
  width: 96%;
}

.leaguesMatches {
  overflow-y: auto;
  overflow-x: hidden;
  // width: 307px;
  height: 249px;
  background: #12141a 0% 0% no-repeat padding-box;
  border: 1px solid #22262e;
  border-radius: 3px;
  opacity: 1;

  // ::-webkit-scrollbar {
  //   display: block !important;
  // }

  text-align: left;
  font: normal normal bold 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #d5901f;
  text-transform: uppercase;
  opacity: 1;

  .nomatchfound {
    text-align: center;
    color: var(--primary);
  }

  .matchDetail {
    height: 83px;
    border-bottom: 1px solid #292b30;
    position: relative;

    .matchDetail-date-country-division {
      padding: 4px 4px 4px 4px;
      .matchDetail-date {
        white-space: nowrap;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;

        text-align: left;
        font: normal normal normal 10px/13px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.5;
        text-transform: capitalize;
      }
      .matchDetail-country {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        text-align: center;
        font: normal normal bold 10px/13px Montserrat;
        letter-spacing: 0px;
        color: #808ea2;
        text-transform: uppercase;
        opacity: 1;
      }
      .matchDetail-division {
        text-align: right !important;
        color: var(--primary);
        text-align: center;
        font: normal normal bold 10px/13px Montserrat;
        letter-spacing: 0px;
        text-transform: uppercase;
        opacity: 1;
      }
    }

    .matchDetail-team-vs-team {
      // color: var(--primary);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: #ffffff;
      text-align: center;
      padding-top: 3px;
      position: absolute; 
      bottom: 9%; 
      width: 100%;
      .matchDetail-team-score {
        text-align: center;
        color: #68717e;
        &.score-left {
        }
        &.score-right {
        }

        .innert-score {
          &.inner-score-left {
          }
          &.inner-score-right {
          }
        }
      }
    }

    .matchDetail-team-vs {
      .team-vs-team {
        align-items: center;
        .team-detail {
          text-align: center;
          font: normal normal bold 10px/13px Montserrat;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
          display: flex;
          align-items: center;
          flex-direction: column;
          .team-detail-img {
            width: 24px;
            height: 24px;
          }

          .team-name-with-dots {
            white-space: nowrap;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-top: 5px;
            // border: 1px solid #000000;
          }

          &.team-d-left {
          }
          &.team-d-right {
          }
        }
        .vs-text {
          justify-content: center;
          display: flex;
          font: normal normal bold 10px/13px Montserrat;
          letter-spacing: 0px;
          color: #68717e;
          text-transform: uppercase;
          opacity: 1;
        }
      }
    }
  }
}
.primaryColor {
  color: var(--primary);
}
.timezone {
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: left;
  font: normal normal normal 10px/13px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
  text-transform: capitalize;
}
