@import "./GameProfileTabs/ChampionStats/champion-stats";
@import "./GameProfileTabs/TournamentStats/tournament-stats";

.update-btn-container {
  flex: auto;
  display: flex;
  justify-content: flex-end;

  > div {
    text-align: right;
  }

  .last-updated-text {
    display: block;
    font-size: 0.7rem;
    color: #999;
  }
}

.no-matches {
  text-align: center;
  padding: 50px;

  svg {
    font-size: 2rem;
  }

  span {
    display: block;
    font-size: 0.9rem;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.game-profile {
  .game-profile-hero-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 300px;
  }

  .game-profile-header {
    display: flex;
    margin: 40px 0px 60px;

    .game-profile-info {
      display: flex;
      flex-direction: column;
      margin-left: 22px;
      justify-content: center;

      h1 {
        text-transform: none;
        margin: 0px;
        padding: 6px 0px 12px;
        font-size: 2.2rem;
      }
    }
  }
}
.role-edit-btn {
  color: var(--primary);
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  font-size: 1rem;
  padding: 0;
}
