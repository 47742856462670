.finder-search-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
 
	.search-icon-container {
		position: absolute;
		left: 12px;
	}

	button,
	input[type="search"],
	input[type="text"] {
		border: none;
		padding: 12px;
		border-radius: 2px;
		outline: none;

		&:active,
		&focus {
			outline: none;
		}
	}

	input[type="search"],
	input[type="text"] {
		flex: 1;
		background-color: $bg-faded;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
		padding-left: 35px;
		color: $input-text-color;

		&::placeholder {
			color: $input-text-color;
		}
	}

	button {
		color: $white;
		background-color: $border-color;
		border-bottom-left-radius: 0px;
		border-top-left-radius: 0px;
		padding-left: 20px;
		padding-right: 20px;
	}
}
.input-icon {
	position: absolute;
	right: 11px;
	top: 11px;
	color: rgba(255, 255, 255, 0.6);
	z-index: 100;
	font-size: 1rem;
}