.game-keys-container {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  text-align: right;

  .game-keys-set-players {
    padding: 18px;
    height: auto;
    margin-top: 25px;
    font-size: 0.8rem;
  }

  .game-keys-upcoming-game {
    width: 500px;
    text-align: left;
    color: white;
    background: $bg-faded;
    border: 1px solid #22262e;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.9);
    padding: 20px;
    margin-top: 25px;

    .team-names {
      margin-bottom: 20px;
    }

    .team-name {
      margin: 7.5px 0 0 0;
    }

    .map-code {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 7.5px 0 0 0;
    }

    .copy-button {
      margin-top: 20px;
    }
  }
}
