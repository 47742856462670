.table-of-contents {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.leagues-gg-rules-body {
  font-size: 1rem;
  line-height: 1.5rem;
  scroll-padding: 50px 0 0 50px;

  ul {
    margin-left: 18px;
  }
  ol {
    margin-left: 18px;
  }

  h1 {
    font-size: 1.7rem;
    line-height: 3rem;
    padding-top: 120px;
    margin-top: -80px;
    -webkit-background-clip: content-box;
    background-clip: content-box;
  }

  h2 {
    // font-size: 1.7rem;
    // line-height: 3rem;
    padding-top: 120px;
    margin-top: -80px;
    -webkit-background-clip: content-box;
    background-clip: content-box;
  }
}
.table-title:hover {
  color: $white;
}
.table-title:active {
  background-color: $white;
}

.column {
  overflow: auto;
  &.table {
    height: 88vh;
  }
}
// body {
//   min-width: 1200px !important;
//   overflow-x: hidden !important;
// }
// body::-webkit-scrollbar {
//     display: block !important;
//     width: 10px;
// }
// ::-webkit-scrollbar-track {
//     background-color: #12141a;
// }
// ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: #22262e;
// }

// .table::-webkit-scrollbar-thumb {
//   border-radius: 0px !important;
// }

.rules-row a {
  background-color: transparent !important;
}

li.uppercase::marker {
  color: transparent !important;
}

.is-current {
  display: list-item;
  list-style-position: inside;
}
