.wide-page-with-tabs {
  .team-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;

    .team-heading {
      display: flex;

      img.team-logo {
        width: 90px;
        height: 90px;
        margin-right: 22px;
        object-fit: contain;
      }

      .team-heading-text {
        display: flex;
        flex-direction: column;
      }

      h1 {
        text-transform: none;
        margin: 10px 0px;
        padding: 0px;
      }

      .text-faded {
        font-weight: 600;
      }
    }
  }

  button.wide-primary {
    margin: 32px 0px;
  }

  .col-player {
    width: 18%;
    margin: 0px 1%;
  }
}

.update-btn-container-rom {
  flex: auto;
  display: flex;
  justify-content: flex-end;

  >div {
    text-align: right;
  }

  .last-updated-text {
    display: flex;
    width: 40%;
    align-items: center;
    font-size: 0.7rem;
    color: #999;
  }
}

.ranked-solo {
  width: 12% !important;
  position: absolute !important;
  margin-left: 314px;
  margin-top: -8px;
}

// .in-small-gutter3 {
//   margin-left: 0 !important;
//   margin-right: 0 !important;
//   // flex-wrap: nowrap !important;
// }

.leagueHeading {
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 30px;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 0px 5px;
  padding-bottom: 18px;
  background: none;
}