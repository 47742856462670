.add-players {
  .ap-title {
    margin: 0 0 20px 0;
  }

  .next-buttons {
    display: flex;
    justify-content: space-between;
  }
}
