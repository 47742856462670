@keyframes loading-pulses {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.news-item {
  padding-bottom: 36px;

  .item-image {
    position: relative;
    height: 260px;
    background-image: none;
    background-size: cover;
    background-position: center center;
    margin-bottom: 11px;
    overflow: hidden;

    .image-cover {
      position: absolute;
      transform: rotate(45deg);
      left: -150px;
      top: -150px;
      width: 200px;
      height: 200px;
      background-color: $content-bg-color;
    }

    span {
      position: absolute;
      right: 15px;
      top: 15px;
      background-color: var(--primary);
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      padding: 4px 8px;
    }
  }
  .news-item-info {
    span {
      background-color: $border-color;
      border-radius: 2px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      padding: 4px 8px;
    }
  }

  .news-item-title {
    h3 {
      line-height: 18px;
      margin-top: 9px;
      margin-bottom: 8px;
    }
  }

  .news-item-excerpt {
    color: $text-faded-color;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.large {
    padding-bottom: 42px;

    .item-image {
      height: 530px;
      width: auto;
    }

    .item-title {
      h3 {
        font-size: 32px;
        line-height: 39px;
        margin-top: 5px;
        margin-bottom: 13px;
      }
    }

    .item-excerpt {
      font-size: 14px;
    }
  }

  &.loading,
  &.large.loading {
    animation: loading-pulses 1.2s infinite;
    .item-image {
      background-image: none !important;
      //background: linear-gradient(white 40px, transparent 0);
      background-color: $text-faded-color;

      span {
        background-color: $content-bg-color;
        color: transparent;
      }
    }

    .item-info {
      span {
        color: transparent;
      }
    }

    .item-title {
      h3 {
        background-color: $text-faded-color;
        color: transparent;
        width: 80%;
      }
    }

    .item-excerpt {
      color: transparent;
      background-color: $text-faded-color;
    }
  }
}
