.your-matches-games {
  .games-header {
    display: flex;

    &.matrics {
      flex-direction: column;
      .mainMatrics {
        margin-bottom: 8px;
        font-weight: bold;
      }

      .underMatrics {
        font-weight:lighter;

        .matricClr_orange{
          color: #d5901f;
        }
        .matricClr_blue{
          color: #3b87b5;
        }
        .matricClr_grey{
          color: #68717e;
        }
      }
    }
    justify-content: center;
    align-items: center;
    img {
      width: 34px;
      height: 34px;

      margin-right: 10px;
    }
  }

  .multi-flex-column {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.games-overview-team-members-list {
  img.team-member-image {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }

  .game-no-set-players {
    width: 100%;
    text-align: center;
    padding: 50px;
    color: #888;

    svg {
      font-size: 2.2rem;
    }

    p {
      text-transform: uppercase;

      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}
