.finder-card {
	border-radius: 2px;

	&.primary {
		border-left: 4px solid var(--primary);
	}

	&.success {
		border-left: 4px solid $success;
	}

	&.warning {
		border-left: 4px solid $warning;
	}

	&.scrim-finder {
		button.content-toggle {
			display: none;
		}
		footer {
			border-top: none;
		}
	}

	header,
	.finder-card-content,
	footer {
		padding: 16px 22px;
	}

	footer {
		border-top: 1px solid $border-color;
		display: flex;
		justify-content: space-between;

		font-size: 12px;

		.faded {
			padding-bottom: 12px;
		}
	}

	
	header {
		border-bottom: 1px solid $border-color;
		display: flex;

		.finder-card-image {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 100%;
			margin-right: 12px;
		}
		.finder-card-image-withoutCropped {
			width: 40px;
			height: 40px;
			object-fit: cover;
			margin-right: 12px;
		}

		.finder-card-info {
			flex: 1;
			display: flex;
		}

		.finder-card-info-text {
			display: flex;
			flex-direction: column;

			.finder-card-info-bottom {
				padding-top: 8px ;
			}
		}

		button.content-toggle {
			border: none;
			background-color: transparent;
		}
	}

	.finder-card-content {
		max-height: 0px;
		opacity: 0;
		padding: 0px 22px;
		transition: max-height 0.3s, opacity 0.3s, padding 0.3s;
		overflow: hidden;

		&.show {
			max-height: 2000px;
			opacity: 1;
			padding: 16px 22px;
		}
	}
}


.displayFlex {
		
	display: flex;

}

.common-profile-images {
    width: 40px;
	height: 40px;
	object-fit: cover;
	border-radius: 100%;
	margin-right: 12px;
  }

.customRow{
	display: flex;
	align-items: center;
}
.finder-card-info-top{
	display: flex;
	flex-wrap: wrap;
}