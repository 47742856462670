.notifications-dropdown {
  .dropdown-content {
    width: 300px !important;
  }

  main {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 !important;

    .all-caught-up {
      text-align: center;
      padding: 20px;
      color: $text-faded-color;

      svg {
        font-size: 2.1rem;
        display: block;
        margin: 0 auto 6px auto;
      }

      span {
        font-size: 0.85rem;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .notification-item {
      display: flex;
      position: relative;
      padding: 10px 15px;
      transition: 0.3s;

      &.completed {
        .notification-item-inner {
          filter: blur(4px);
        }

        &.hide {
          padding: 0;
          height: 0 !important;
          overflow: hidden;
          opacity: 0;
        }

        .completed-text {
          position: absolute;
          z-index: 9;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          font-size: 0.8rem;
          font-weight: bold;
          text-transform: uppercase;

          svg {
            margin-right: 5px;
            font-size: 1.2rem;
          }
        }

        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(lighten($success, 100%), 0.05);
          top: 0;
          left: 0;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .notification-item-image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        margin: 2px 10px;
        margin-left: 0px;
        background-color: #292929;
        background-size: cover;
        background-position: 50% 50%;
        line-height: 45px;
        position: relative;
        
        span {
          display: table;
          margin: 0 auto;
        }

        .new-notif-dot {
          position: absolute;
          top: 0px;
          right: 0px;
          width: 9px;
          height: 9px;
          background-color: var(--primary);
          opacity: 0.9;
          border-radius: 100%;
          box-shadow: -2px 2px 10px rgba(var(--primary), 0.7);
        }
      }
    }

    .notification-item-title {
      display: flex;
      justify-content: space-between;
      height: 20px;

      button {
        color: $text-faded-color;
        font-size: 1.3rem;
      }
    }

    h3 {
      font-size: 0.75rem;
      margin: 5px 0 5px 0;
    }

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin: 7.5px 0;
      color: $text-faded-color;
    }

    small {
      display: block;
      font-size: 0.7rem;
      color: darken($text-faded-color, 5%);
    }

    .notification-actions {
      margin-top: 12.5px;
    }
  }

  .view-all-link {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    text-decoration: none;
    border-top: 1px solid $border-color;
    // background: lighten($dropdown-bg, 5%);
  }
}
