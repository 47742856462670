.slider-container{
  .arrows{
    .slick-arrow {
      &.slick-prev {
        opacity: 1;
        margin-top: -1.8px;
        margin-left: 2px;

        &::before {
          opacity: 1;
        }
      }

      &.slick-next {
        opacity: 1;
        margin-top: -1.8px;
        margin-right: 1px;

        &::before {
          opacity: 1;
        }
      }

    }
  }
  
}
