.modal {
  .modal-header {
    .headerTitle {
      font-size: 1rem;
      line-height: 19px;
      text-transform: uppercase;
    }
  }
  .description {
    color: #68717e;
    font-size: 10px;
    line-height: 14px;
  }
  .button {
    width: 100%;
    color: white;
  }
  .button-primary {
    background-color: #3eabf0;
  }
  .button-danger {
    background-color: #f11857;
  }
  .closeButton {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: -20px;
  }
}
