
.banner{
  background-color: #12141a;
  padding-inline: 4rem;
  margin-block: 4rem;
  &_image{
    object-fit: contain;

    &_container{
      width: 33%;
      display:flex;
      justify-content: space-around;
    }
  }
  &_inner{
    display: flex;
    gap: 2rem;
    text-align: center;
    width: 100%;
    justify-content: space-between;
    
  }
  &_content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width:33%;
    margin-block: 3rem;
    &_text{
      margin-block: 2rem;
    }
    &_btn{
      padding: 1rem;
      background-color: white;
      color: #12141a;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;

      border-radius: 2px;
      transition: all 0.2s ease-in;
      &:hover{
        background-color: $primary;
        color: white;
      }
    }
    &_heading{
      font-size: 3rem;
      margin: 0px;
      line-height: 1.2;
      font-weight: 700;
      text-align: center;
    }
    &_text{
      text-align: center;
    }
  }
}