.user-profile {
  padding: 30px 20px;
}

.user-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  h1 {
    font-size: 2.1rem;
    font-weight: normal;
    margin: 0;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 20px 0 0 0;
  }

  .header-avatar {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: white;
    background-size: cover;
    background-position: center;
  }
}

.user-profile-info {
  margin-bottom: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .user-info-item {
    width: 100%;

    .item-value {
      font-size: 1.2rem;
      height: 25px;

      img {
        display: inline-block;
        vertical-align: top;
        height: 32px;
        margin-top: -5px;
        margin-right: 10px;
      }
    }

    .item-label {
      color: #999;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }
}

.user-profile-socials {
  display: flex;
  justify-content: space-between;
}

.user-profile-teams {
  margin: 90px 0;

  h2 {
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 20px;
  }

  .profile-team {
    display: flex;
    margin-bottom: 20px;

    .team-avatar {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: white;
      background-size: cover;
      background-position: center;
      margin-right: 15px;
    }

    .team-details {
      p {
        margin: 0;
      }

      .team-name {
        line-height: 1.5rem;
        font-size: 1.1rem;
        margin-bottom: 7.5px;
      }

      .team-game {
        font-size: 0.9rem;
        text-transform: uppercase;
        color: #999;
      }
    }
  }
}

.user-profile-players {
  margin: 90px 0;

  h2 {
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 35px;
  }

  .profile-player {
    display: block;
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
    width: 100%;
    padding: 60px 20px;
    text-align: center;
    background: #444;

    h3 {
      font-size: 1.6rem;
      text-transform: uppercase;
    }
  }
}
