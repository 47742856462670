.player-position-form {
  display: flex;

  .position-info {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }

  .player-position-option-col {
    flex: 1;

    padding-right: 30px;

    &:last-child {
      padding-right: 0px;
    }
  }
}

