@import "./AuthModal/auth-modal";
@import "./CreateTeamModal/create-team-modal";
@import "./EditImageModal/edit-image-modal";
@import "./CreateScrimModal/create-scrim-modal";
@import "./EditScrimModal/edit-scrim-modal";
@import "./CreatePlayerModal/create-player-modal";
@import "./reschedule/reschedule";
@import "./CalendarSyncModal/calendarSyncModal";
@import "./SyncCalendarDetailModal/sync-calendar-detail";
@import "./CreateAppointment/create_apppointment";
@import "./CalendarEventModal/calendar_event.scss";

.ReactModalPortal {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 0px;
  left: 0px;
  top: 0px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(50, 50, 50, 0.8) !important;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 450px;
  transform: translate(-50%, -50%);
  background: $bg-faded;
  color: #fff;
  outline: 0;
  border-radius: 3px;
  z-index: 999;
  max-height: calc(100vh - 100px);

  //overflow: hidden;
  &.match-modal {
    width: 1100px;
  }

  &.modal-inline {
    position: relative;

    &.on-page {
      z-index: 9;
    }
  }

  .modal-close {
    top: 10px;
    right: -45px;
    position: absolute;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }

  .modal-scrollable {
    overflow: auto;
    max-height: calc(100vh - 100px);
  }

  .modal-header {
    text-align: center;
    padding: 15px 20px;
    border-bottom: 1px solid $tab-border-color;

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: $text-faded-color;
    }
  }

  .modal-body {
    padding: 20px;
    max-height: calc(100vh - 280px) !important;
    overflow-y: auto !important;

    .add-images {
      .image-card {
        width: 100%;
        height: 125px;
        margin-bottom: 20px;
        background-color: rgba(255, 255, 255, 0.025);
        background-size: cover;
        background-position: center;
        border: 1px solid rgba(255, 255, 255, 0.15);
        border-radius: 3px;
        text-align: center;
        line-height: 125px;
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;

        &.round-logo {
          width: 125px;
          margin: auto;
          margin-bottom: 20px;
          border-radius: 100%;
        }
      }

      .next-buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .modal-information {
    border-top: 1px solid #22262e;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px;
    background: #22262e;

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: normal;
      color: #68717e;
    }
  }
}

//  match modal
.match-modal {
  .match-modal-header {
    min-height: 227px;
    background-size: cover;
    background-position: center;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 0;
    position: relative;
    // background-image: linear-gradient(to top, rgb(18, 20, 26) 10%, rgba(18, 20, 26, 0) 150%), url("../../img/game-profile-cover.png");

    &:before {
      position: absolute;
      content: "";
      width: 17px;
      height: 20px;
      background-image: url("../../img/play.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: "";
      width: 14px;
      height: 14px;
      background-image: url("../../img/cross.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      right: 20px;
      bottom: 20px;
    }
  }
}
