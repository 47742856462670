.prize-item {
  img.image-40 {
    border-radius: 100%;
    margin-right: 20px;
  }
  img.image-40-logoCropped {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}
