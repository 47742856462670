.account-settings-form {
  .form-row {
    padding: 12px 0px;
  }

  label {
    font-weight: bold;
    text-transform: uppercase;
    
  }

}

@import "./SettingsTabs/MyAccount/DeleteAccountModal/delete-account-modal";
@import "./SettingsTabs/MyPlayersCoaches/my-players-coaches";
