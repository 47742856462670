.match-modal {
  .match-modal-header {
    min-height: 212px;
    padding-bottom: 20px;

    &:after {
      display: none;
    }

    .auth-modal-tabs {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.lineup-team{
  background: #15181f;
  padding: 15px 15px 0px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  border: 1px solid #2A2C31;
  li{
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 0 8px;
    border-top: 1px solid #22262e;
  }
}
.modal-tabs-content{
  // max-height: 300px;
  overflow: auto;
  padding: 20px;
  background: #12141a;

}
.par-goto-team{
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
  
  a{
    color: #3eabf0;
    text-decoration: none;
    .icon{
      margin-left: 3px;
    }
  }
}

.customModal {
  transform:translate(-50%, 2%) !important;
  top: 0 !important;
  overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 30px) !important;
}
.placeholder-team-none{
pointer-events: none;
opacity: 0.5;
}