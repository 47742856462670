.Toastify__toast {
  border-radius: 4px !important;
  font-family: inherit !important;
  font-size: 0.9rem;
  font-weight: 500;
  z-index: 999999;
}

.Toastify__toast--success {
  background: $success !important;
}

.Toastify__toast--error {
  background: $danger !important;
}
