.announcements-tab {
	.announcement-item {
		padding-top: 22px;

		.announcement-item-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.announcement-item-header-info {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 40px;
					height: 40px;
					border-radius: 20px;
					object-fit: cover;
					margin-right: 10px;
				}

				.name-bio {
					display: flex;
					justify-content: center;
					flex-direction: column;

					div:first-child {
						padding: 4px 0px;
					}
				}
			}
		}

		.announcement-content {
			padding: 16px 0px;
			padding-bottom: 22px;
			border-bottom: 1px solid $border-color;
		}

		.col-3 {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:first-child {
			padding-top: 0px;
		}

		&:last-child .announcement-content {
			border-bottom: none;
		}
	}
}
