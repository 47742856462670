.player-overview-row {
  margin-bottom: 30px;

  .row-title {
    font-size: 1.2rem;
  }

  .row-content {
    p {
      margin: 0;
      line-height: 1.8rem;
    }

    .overview-card {
      display: inline-block;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;

      .card-icon {
        width: 20px;
        height: 20px;
        background: #ddd;
        border-radius: 100%;
      }

      .card-desc {
        line-height: 0.8rem;
        margin: 15px 0;
      }

      .card-label {
        font-size: 0.9rem;
        line-height: 0.8rem;
        color: #999;
      }
    }
  }
}
