.mainCalendar {
  .calenderevent {
    width: 100%;
    text-align: center;

    img {
      width: 42px;
    }

    .teamArea {
      height: 85px;
      padding-top: 12%;
    }

    .teamImage {
      width: 46%;
      float: left;
      text-align: right;
    }

    .teamName {
      width: 46%;
      float: right;
      line-height: 43px;
      text-align: left;
    }
  }

  .eventContent {
    margin-top: -12px;
    margin-left: 8px;
    font-size: 12px;
    margin-top: -9px;

    .eventHeading {
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 3px !important;
    }
  }
  .open-popover {
    height: 100%;
  }

  .thead {
    background: #03060d !important;
  }

  .side-bar-calender {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-color: #0f1218;
  }

  .fc-theme-standard td {
    border-color: #0f1218;
  }

  .fc-theme-standard th {
    border-color: #0f1218;
  }

  // .fc-daygrid-day {
  //     height: 215px
  // }

  .fc-toolbar-title {
    display: inline !important;
  }

  .fc-day-today {
    background-color: #100f0f !important;
    font-size: 80%;
  }

  .fc-highlight {
    border-color: transparent;
    background: none !important;
  }

  .fc {
    .fc-scroller-liquid-absolute {
      overflow-x: hidden !important;
    }
    .dayGridContent {
      font-size: 11px;
      text-transform: uppercase;
      line-height: 16px;
    }

    .eventInner {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 2px;
      margin-top: -1px;
    }

    .eventInnerCoppy {
      white-space: pre-wrap;
      margin-left: 2px;
      margin-top: -1px;
    }

    .fc-daygrid-dot-event {
      align-items: flex-start;
    }

    .fc-toolbar-title {
      font-size: 1em;
      text-transform: uppercase;
    }

    .fc-button-primary {
      text-transform: uppercase;
      font-weight: bold;
      height: 34px;
      font-size: 14px;

      color: white;
      background-color: black;
      border-color: var(--fc-button-hover-border-color, #1a252f);

      &:active {
        background: none !important;
      }
    }

    .fc-currentWeek-button {
      text-transform: uppercase;
      font-weight: bold;
      height: 29px;
      font-size: 14px;
      width: 79px;
      border: none;
      color: white;
      background-color: #12141a;
      padding: 1px;

      &:focus {
        border: none !important;
        box-shadow: none;
      }

      &:active {
        border: none !important;
        background-color: #12141a;
        box-shadow: none;
      }
    }

    .fc-button-primary:disabled {
      background-color: black;
    }

    .fc-timegrid-divider {
      padding: 0px !important;
    }

    .fc-timegrid-slot {
      height: 3em !important;
      border-bottom: 0 /* each cell owns its top border */;
    }

    .fc-scrollgrid-shrink-cushion {
      color: #68717e;
      font-weight: bold !important;
    }

    .fc-col-header-cell-cushion {
      color: #68717e;
      padding: 22px 4px;
      text-transform: uppercase;
      font-weight: bold !important;
    }

    .fc-popover-body {
      background-color: #12141a;
    }
    .fc-popover-header {
      background-color: #262424;
    }

    .fc-timegrid-col-events {
      margin: 2px !important;
      border-top: #3788d8;
    }

    .fc-daygrid-day-top {
      display: flex;
      flex-direction: row;
      text-align: center;
      margin-left: 45%;
      margin-top: 10px;
      font-weight: bold;
    }

    .fc-daygrid-event-dot {
      margin: 0 4px;
      border-radius: 2px !important;
      box-sizing: inherit !important;
      width: 14px !important;
      height: 0px !important;
      border: 7px solid #3788d8;
    }

    .fc-daygrid-day-bottom {
      text-transform: uppercase;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 0.67em;
      margin-top: 4px !important;
      height: 25px;
    }

    .fc-daygrid-more-link {
      background-color: #1a1d26;
      position: absolute;
      right: 1px;
      left: 1px;
      bottom: 10px;
      padding: 5px;
    }

    .fc-daygrid-event {
      cursor: pointer !important;
      margin-top: 0px;
    }

    .fc-timegrid-event {
      cursor: pointer !important;
    }

    .fc-event-time {
      display: inline-table;
      color: #606874;
      font-weight: bold;
    }

    .fc-event-title {
      margin-left: 1px;
      text-overflow: ellipsis;
    }

    .fc-day-other {
      .fc-daygrid-day-frame {
        background: #070b11 !important;
      }
    }

    .fc-day-today {
      background: #03060d !important;

      .fc-daygrid-day-top {
        .fc-daygrid-day-number {
          color: var(--primary);
          // background: #383535 !important;
          background: transparentize($primary, 0.9) !important;
          padding: 6px;
          border-radius: 15px;
          margin-top: -2px;
          width: 31px;
          font-size: 16px;
        }
      }

      .fc-scrollgrid-sync-inner {
        .fc-col-header-cell-cushion {
          color: var(--primary);
          // background: #383535 !important;
          background: transparentize($primary, 0.9);
          padding: 8px;
          border-radius: 40px;
          margin-top: 6px;
          width: 56px;
          font-size: 13px;
          line-height: 17px;
        }
      }

      .fc-daygrid-day-frame {
        background: #12141a !important;
      }
      .fc-highlight {
        &:active {
          border-color: yellow !important;
        }
      }
    }

    .fc-daygrid-week-number {
      background-color: transparent !important;
      font-weight: bold !important;
      color: #68717e !important;
    }

    .fc-daygrid.fc-dayGridMonth-view.fc-view {
      td {
        border: none !important;
        background-color: 1px solid #03060d !important;
        height: 8.5em !important;
      }

      th {
        border: none !important;
      }

      .fc-highlight {
        border: 1px solid var(--primary) !important;
        background: none !important;
      }

      .fc-daygrid-day.fc-day-today {
        background: none !important;
        // border: 1px solid var(--primary) !important;
      }

      .fc-daygrid-day-frame {
        background: #12141a;
      }

      .fc-scrollgrid-sync-inner {
        margin-left: 2px !important;
        margin-right: 2px !important;
        border: 5px solid #03060d !important;
      }

      .fc-scrollgrid {
        border: none;
      }
    }

    .fc-header-toolbar {
      position: relative;
      top: -80px !important;
    }
    .fc-view-harness {
      position: relative;
      top: -80px;
    }

    .fc-event-mirror {
      background: #19db60;
    }

    .fc-calendarViewButton-button {
      text-transform: uppercase;
      font-weight: bold;
      height: 34px;
      font-size: 14px;

      color: white;
      background-color: black;

      svg {
        margin-left: 10px;
      }
    }

    .fc-button-primary:focus {
      box-shadow: none !important;
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror {
      // box-shadow: none !important;
      border-color: transparent;
      box-shadow: 0 -1px 10px #00000033;
      margin-right: 13px;
      border-radius: 5px;
    }
  }
}
/*double Click*/
.mainCalendar .fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  margin-left: 0%;
  margin-top: 10px;
  font-weight: bold;
}
.fc .fc-daygrid-day-number {
  height: 100px;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  top: 30%;
}
.mainCalendar .fc .fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  color: var(--primary);
  background: rgba(62, 171, 240, 0.1) !important;
  padding: 6px;
  border-radius: 15px;
  margin-top: -2px;
  width: 31px;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  cursor: pointer;
}
/*double Click*/
.calander-sidebar-scroll{
  max-height:300px;
  overflow-x: hidden;
  width: 200px;
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: #f11857;
  border-color: var(--fc-now-indicator-color, #f11857);
}
.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  border-width: 5px 0 5px 6px !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}