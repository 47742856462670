.league-no-matches {
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 50px 0;

  img {
    width: 30px;
    margin-bottom: 5px;
  }
}

.not-found {
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 50px 0;

  img {
    width: 30px;
    margin-bottom: 5px;
  }
}

.leagues-result-item {
  .faded {
    color: rgba(255, 255, 255, 0.5);
  }

  img.team-member {
    width: auto;
    height: 30px;
    margin-right: 14px;
    opacity: 0.5;
  }
  .col-2,
  .col-3,
  .col-4 {
    align-items: center;
    display: flex;
  }
  .team-member-info {
    display: flex;
    flex-direction: column;
  }

  .primary-link {
    width: 100%;
    text-align: right;
  }

  .results-detail-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .pill {
      margin: 0px 28px;
      flex-shrink: 0;
    }

    .versus {
      margin: 0px 25px;
      font-size: 0.7rem;
    }

    .results-detail-team {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      text-decoration: none;
      // padding-left: 30px;

      .text-14 {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .teamImage {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .teamImageCropped {
        border-radius: 0% !important;
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
}
